import React, {useEffect, useState} from "react";
import "./../App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {mainpg, poshiv, remont, sudyi, xim} from "./products";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

export const HeaderSearch = () =>  {
	return(
		<div className="header__search">
			<button className="header__category"><img src="/images/icons/Burger.svg" alt="Burger.svg"/> Все категории</button>

			<SearchBig/>

			<ul className="pageInfo">
				<li>
					<i></i> <span>Размещено объявлений: 345</span>
				</li>
				<li>
					<i></i> <span>Последний заказ: 1 мин назад</span>
				</li>
			</ul>

			<div className="select__city"><span>Санкт-Петербург</span> <img src="/images/icons/Select__city.svg" alt=""/></div>
		</div>
	);
};

export const SelectFilter = ({SelectFilterName1}) =>{
	return(
		<>
			<ul className="select-filter__container">
				<li className="select-filter"><span>{SelectFilterName1}</span>
					<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="filter-delete">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M4.99995 4.63878L9.13848 0.5L10 1.36158L5.86148 5.50035L9.99929 9.63842L9.13777 10.5L4.99995 6.36193L0.86223 10.4999L0.00070497 9.63833L4.13843 5.50035L0 1.36167L0.861525 0.500094L4.99995 4.63878Z" fill="#818189"/>
					</svg>

				</li>
			</ul>
		</>
	)
};

export const SearchBig = () =>{
	const [searchQuery, setSearchQuery] = useState("");
	return(
		<label className="search__container">
			<input
				type="text"
				placeholder="Поиск"
				className="search-input"
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
			/>
			<button className="search__btn">Найти</button>
		</label>
	)
}

export const SearchFilter = () =>{
	const [searchQuery, setSearchQuery] = useState("");
	return(
		<label className="search__container">
			<input
				type="text"
				placeholder="Поиск"
				className="search-input pc-src filter-input"
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
			/>
			<button className="search__btn filter-input-btn">Найти</button>
		</label>
	)
}

export const FilterMenu = () =>{
	const SwitchName = ("Название фильтра 1");
	const SelectFilterName1 = ("Вратарь");
	const cities = [
		"Москва",
		"Санкт-Петербург",
		"Новосибирск",
		"Екатеринбург",
		"Казань",
	];
	const ageGroups = [
		"Взрослые (>18 лет)",
		"Подростки (15-17 лет)",
		"Подростки (12-14 лет)",
		"Дети (12-14 лет)",
		"Дети (8-12 лет)",
		"Дети (5-7 лет)",
	];
	const handleCitySelect = (city) => {
		setSelectedCity(city);
		setCityDropdownOpen(false);
	};
	const [selectedCity, setSelectedCity] = useState("Москва");
	const [cityDropdownOpen, setCityDropdownOpen] = useState(false);
	const [priceRange, setPriceRange] = useState({ min: "", max: "" });
	const {id} = useParams();
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [expanded, setExpanded] = useState(false);
	const [selectedDay, setSelectedDay] = useState(null);
	const [selectedTimes, setSelectedTimes] = useState([]);
	const [today, setToday] = useState({
		day: new Date().getDate(),
		month: new Date().getMonth(),
		year: new Date().getFullYear(),
	});
	const months = [
		{month: 'Январь', days: 31},
		{month: 'Февраль', days: 28},
		{month: 'Март', days: 31},
		{month: 'Апрель', days: 30},
		{month: 'Май', days: 31},
		{month: 'Июнь', days: 30},
		{month: 'Июль', days: 31},
		{month: 'Август', days: 31},
		{month: 'Сентябрь', days: 30},
		{month: 'Октябрь', days: 30},
		{month: 'Ноябрь', days: 30},
		{month: 'Декабрь', days: 31},
	];
	const timeOptions = {
		1: [{time: '12:00', price: 25000}, {time: '14:00', price: 30000}],
		2: [{time: '10:00', price: 20000}, {time: '16:00', price: 27000}],
	};
	const price = useState("2000")
	const toggleTimeSelection = (time, price) => {
		if (selectedTimes.includes(time)) {

			setSelectedTimes(selectedTimes.filter(t => t !== time));
			setTotalPrice(prevPrice => prevPrice - price);
		} else {

			setSelectedTimes([...selectedTimes, time]);
			setTotalPrice(prevPrice => prevPrice + price);
		}
	};
	const weekDays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
	const getDayOfWeek = (year, month, day) => {
		const date = new Date(year, month, day);
		return weekDays[date.getDay()];
	};
	const getFirstDayOfWeek = (year, month) => {
		const firstDay = new Date(year, month, 1);
		return firstDay.getDay();
	};
	const rotateWeekDays = (offset) => {
		const rotatedDays = [...weekDays];
		for (let i = 0; i < offset; i++) {
			rotatedDays.push(rotatedDays.shift());
		}
		return rotatedDays;
	};
	const selectMonth = (index) => {
		setSelectedMonth(index);
		setExpanded(false);
		setSelectedDay(null);
	};
	const selectDay = (day) => {
		setSelectedDay(day);
	};
	const toggleExpand3 = () => {
		setExpanded(!expanded);
	};
	const [totalPrice, setTotalPrice] = useState(0);
	const [isOpen2, setIsOpen2] = useState(false);
	const [selectedTab, setSelectedTab] = useState('All');
	const toggleOpen2 = () => {
		setIsOpen2(!isOpen2);
	};

	return(
		<div className="filterMenu">
			<div className="filterMenu__head">
				<h1>Выбранный фильтры</h1>
				<div className="clear">Очистить</div>
			</div>

			<SelectFilter SelectFilterName1={SelectFilterName1}/>

			<SearchFilter/>

			<main className="filterMenuMain">
				<div className="filter-group">
					<label style={{ fontWeight: 700 }}>Выбрать город</label>
					<div className="custom-select">
						<div className="dropdown-container">
							<div className="dropdown-trigger" onClick={() => setCityDropdownOpen(!cityDropdownOpen)}>
								<span className="dropdown-label">{selectedCity}</span>
								<span className="dropdown-value"></span>
							</div>
						</div>
						{cityDropdownOpen && (
							<div className="dropdown">
								{cities.map((city) => (
									<button
										key={city}
										onClick={() => handleCitySelect(city)}>
										{city}
									</button>
								))}
							</div>
						)}
					</div>
				</div>

				<div className="filter-group" style={{display: "flex", flexDirection: "column", gap: 16, marginTop: 8,}}>
					<label style={{ fontWeight: 700 }}>Стоимость, ₽</label>
					<div className="price-range">
						<input
							type="number"
							value={priceRange.min}
							onChange={(e) =>
								setPriceRange({ ...priceRange, min: e.target.value })
							}
							placeholder="От 0"
						/>
						<input
							type="number"
							value={priceRange.max}
							onChange={(e) =>
								setPriceRange({ ...priceRange, max: e.target.value })
							}
							placeholder="До 45000"
						/>
					</div>
					<div className="price-slider-container">
						<input
							type="range"
							min="0"
							max="22500"
							value={priceRange.min}
							className="price-slider"
						/>
						<input
							type="range"
							min="22500"
							max="45000"
							value={priceRange.max}
							className="price-slider"
						/>
					</div>

					<div className="filter-item">
						<Switch SwitchName={SwitchName}/>
					</div>

					<button
						className="apply-btn"
						style={{ marginTop: 16 }}>
						Применить фильтр
					</button>

					<div className="calendar">
						<div onClick={toggleOpen2}>
							<h1>Дата и время</h1>
							<p></p>
						</div>

						<div className={`mxw  ${isOpen2 ? 'open' : ''}`}>
							<div className="months" >
								{months.map((m, index) => (
									<button
										key={index}
										className={`month-button ${selectedMonth === index ? 'active' : ''}`}
										onClick={() => selectMonth(index)}
									>
										{m.month}
									</button>
								))}
							</div>

							{selectedMonth !== null && (
								<div className={`days ${expanded ? 'days-active' : ''}`}>
									{expanded && (
										<div className="year-display">
											<h2>{months[selectedMonth].month} 2024</h2> {/* Добавляем строку с годом */}
										</div>
									)}

									<div className="days-grid-cont">


										<div className={`days-grid ${expanded ? 'grid-active' : ''}`}>
											{rotateWeekDays(getFirstDayOfWeek(2024, selectedMonth)).map((day, index) => (
												<div key={index} className="week-day">
													{day}
												</div>
											))}
											{selectedMonth !== null && selectedMonth > 0 && months[selectedMonth] && (
												Array.from({length: getFirstDayOfWeek(2024, selectedMonth)}).map((_, index) => {
													const prevMonthIndex = selectedMonth === 0 ? 11 : selectedMonth - 1; // Декабрь для января
													const prevMonthDays = months[prevMonthIndex]?.days || 0; // Количество дней в предыдущем месяце
													const day = prevMonthDays - getFirstDayOfWeek(2024, selectedMonth) + index + 1;
													return (
														<div key={`prev-${index}`} className="day gray-out">
															<div>{day}</div>
															{/* Предыдущие числа серым */}
														</div>
													);
												})
											)}


											{selectedMonth !== null && months[selectedMonth]?.days && (
												Array.from({length: months[selectedMonth].days}, (_, i) => i + 1).map(day => (
													<div
														key={day}
														className={`day ${selectedDay === day ? 'selected-day' : ''} ${day === today.day && selectedMonth === today.month && today.year === 2024 ? '' : ''}`}
														onClick={() => selectDay(day)}>
														<div>{day}<p>, {getDayOfWeek(2024, selectedMonth, day)}</p></div>
														<div className="priced">{price} р.</div>

														{day === today.day && selectedMonth === today.month && today.year === 2024 && (
															<div className="point"></div>
														)}
													</div>
												)).slice(0, expanded ? months[selectedMonth].days : 7)
											)}
											{!expanded && (
												<p className="next-month-heading">{months[selectedMonth + 1]?.month.slice(0, months[selectedMonth + 1]?.month === 'June' || months[selectedMonth + 1]?.month === 'July' ? 4 : 3)}</p>
											)}

											{selectedMonth !== null && months[selectedMonth]?.days && (
												Array.from({length: 42 - (getFirstDayOfWeek(2024, selectedMonth) + months[selectedMonth].days)}).map((_, index) => (
													<div key={`next-${index}`} className="day gray-out">
														<div>{index + 1}</div>
														{/* Следующие числа серым */}
													</div>
												)).slice(0, expanded ? undefined : 7)
											)}
										</div>

										<button className="expand-button" onClick={toggleExpand3}>
											{expanded ? <div className="arrow-up"></div> : <div className="arrow-down"></div>}
										</button>
									</div>
								</div>
							)}

							{selectedDay && (
								<div className="time-options">
									{timeOptions[selectedDay]?.length > 0 ? (
										timeOptions[selectedDay].map((option, index) => (
											<div
												key={index}
												className={`time-option ${selectedTimes.includes(option.time) ? 'selected-time' : ''}`}
												onClick={() => toggleTimeSelection(option.time, option.price)}
											>
												{option.time} · {option.price}р.
											</div>
										))
									) : (
										<div className="iskl">Пока предложений нету</div>
									)}
								</div>
							)}

							{selectedTimes.length > 0 && (
								<button className="book-button">
									Забронировать · {totalPrice} р
								</button>
							)}
						</div>

					</div>
				</div>
			</main>
		</div>
	);
};

export const HeadListProduct = ({ selectedFormat, setSelectedFormat, setItemsPerPage, setCardsPerRow }) => {
	const [sortDropdown, setSortDropdown] = useState(false);
	const [formatDropdown, setFormatDropdown] = useState(false);

	const HandleOpenSortModal = (status) => {
		setSortDropdown(sortDropdown === status ? !sortDropdown : status);
	};

	const HandleSelectFormat = (format) => {
		setSelectedFormat(format);
		setFormatDropdown(false);

		// Настраиваем количество карточек в строке и общее количество карточек на странице
		switch (format) {
			case "four-view":
				setCardsPerRow(4); // 4 карточки в строке
				setItemsPerPage(12); // 3 строки по 4 карточки
				break;
			case "three-view":
				setCardsPerRow(3); // 3 карточки в строке
				setItemsPerPage(9); // 3 строки по 3 карточки
				break;
			case "one-view":
				setCardsPerRow(1); // 1 карточка в строке
				setItemsPerPage(4); // 4 строки по 1 карточке
				break;
			default:
				setCardsPerRow(4); // Значение по умолчанию: 4 карточки в строке
				setItemsPerPage(12); // 3 строки по 4 карточки
		}
	};

	const HandleOpenFormatModal = (status) => {
		setFormatDropdown(formatDropdown === status ? !formatDropdown : status);
	};

	return (
		<div className="HeadListProduct">
			<div
				className="HeadListProduct__dropdown-container left"
				onClick={() => HandleOpenSortModal(sortDropdown)}
			>
				<div>
					<img src="/images/icons/SortBtn.svg" alt="" />
					<span>Сортировка</span>
					<img src="/images/icons/ArrowDown.svg" alt="" />
				</div>
				{sortDropdown && (
					<ul className="HeadListProduct__dropdown">
						<li>
							<span className="dropdown__apply">sss</span>{" "}
							<img src="/images/icons/apply.svg" alt="" />
						</li>
						<li>
							<span>sss</span> <img src="/images/icons/apply.svg" alt="" />
						</li>
					</ul>
				)}
			</div>

			<div className="HeadListProduct__rightside">
				<div
					className="HeadListProduct__dropdown-container"
					onClick={() => HandleOpenFormatModal(formatDropdown)}
				>
					<div>
						<img src="/images/icons/dots.svg" alt="" />{" "}
						<span>Формат отображения</span>
					</div>
					{formatDropdown && (
						<ul className="HeadListProduct__dropdown right">
							<li onClick={() => HandleSelectFormat("four-view")}>
								<div>
									<img src="/images/icons/FourView.svg" alt="" />
									<span className="dropdown__apply">Сетка (4 в ряд)</span>
									<img className="check" src="/images/icons/apply.svg" alt="" />
								</div>
							</li>
							<li onClick={() => HandleSelectFormat("three-view")}>
								<div>
									<img src="/images/icons/ThrideView.svg" alt="" />
									<span>Три в ряд</span>
								</div>
							</li>
							<li onClick={() => HandleSelectFormat("one-view")}>
								<div>
									<img src="/images/icons/OneView.svg" alt="" />
									<span>Один в ряд</span>
								</div>
							</li>
						</ul>
					)}
				</div>
				{/*<div style={{ flexDirection: "row-reverse" }}>*/}
				{/*	<img src="/images/icons/ArrowDown.svg" alt="" /> <span>Меню</span>*/}
				{/*</div>*/}
			</div>
		</div>
	);
};

export const ListProduct = ({ selectedFormat }) => {
	const navigate = useNavigate();
	const [currentSlides, setCurrentSlides] = useState({});
	const settings = (productId) => ({
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		afterChange: (current) => handleSlideChange(productId, current),
	});
	const handleSlideChange = (productId, current) => {
		setCurrentSlides((prevState) => ({
			...prevState,
			[productId]: current,
		}));
	};
	const groupedProducts = [];
	for (let i = 0; i < mainpg.length; i += 2) {
		groupedProducts.push(mainpg.slice(i, i + 2));
	}

	return (
		<div className={`product-scroll-container ${selectedFormat}`}>
			<div className="products-row">
				{groupedProducts.length > 0 ? (
					groupedProducts.map((group, index) => (
						<div className="product-column" key={index}>
							{group.map((product) => (
								<ProductScrollLine group={group} index={index} product={product}/>
							))}
						</div>
					))
				) : (
					<div className="no-products">
						<p>По выбранным фильтрам объявлений нет</p>
					</div>
				)}
			</div>
		</div>
	);
};

export const EtcListProduct = () =>{
	const groupedProducts = [];
	for (let i = 0; i < mainpg.length; i += 1) {
		groupedProducts.push(mainpg.slice(i, i + 1));
	}

	return (
		<div className="EtcProductList">
			<h1 className="step__title">Сборы</h1>

			<div className="product-scroll-container">
				<div className="products-row">
					{groupedProducts.length > 0 ? (
						groupedProducts.map((group, index) => (
							<div className="product-column" key={index}>
								{group.map((product) => (
									<ProductScrollLine group={group} index={index} product={product}/>
								))}
							</div>
						))
					) : (
						<div className="no-products">
							<p>По выбранным фильтрам объявлений нет</p>
						</div>
					)}
				</div>
			</div>
		</div>

	);
};

export const ProductScrollLine = ({ groupedProducts, product, cardsPerRow }) => {
	const navigate = useNavigate();
	const [currentSlides, setCurrentSlides] = useState({});

	const handleSlideChange = (productId, current) => {
		setCurrentSlides((prevState) => ({
			...prevState,
			[productId]: current,
		}));
	};

	const productClass = `product product-${cardsPerRow}-row ${cardsPerRow === 4 ? 'product-4-row' : ''}`;

	return (
		<div
			className={productClass}
			key={product.id}
			onClick={() => {
				navigate(`/equipment/${product.id}`, {
					state: { products: groupedProducts },
				});
			}}
		>
			<div className="product-image-slider">
				<Swiper
					spaceBetween={10} // Отступы между слайдами
					slidesPerView={1} // Показывать одно изображение
					loop={true} // Зацикливание
					onSlideChange={(swiper) => handleSlideChange(product.id, swiper.activeIndex)}
				>
					{product.images &&
						product.images.map((image, i) => (
							<SwiperSlide key={i}>
								<img
									className="product-image"
									src={image}
									alt={`Product Image ${i + 1}`}
								/>
							</SwiperSlide>
						))}
				</Swiper>
				<div className="slider-counter">
					{(currentSlides[product.id] || 0) + 1} из {product.images.length}
				</div>
			</div>

			<div className="product-info">
				<h2>{product.name}</h2>
				<p>
					от {product.price}р{" "}
					<span className="old-price">{product.oldPrice}р</span>
				</p>
				<p>{product.location}</p>
				<p className="rating"> {product.rating} <p>(0)</p></p>
				<Favorite/>
				<button className="AddToCart Second">
					<svg width="39" height="25" viewBox="0 0 39 25" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M14 1H16.088C17.384 1 18.4039 2.11599 18.2959 3.39997L17.3 15.3518C17.132 17.3078 18.6799 18.9878 20.6479 18.9878H33.4278C35.1557 18.9878 36.6677 17.5718 36.7997 15.8558L37.4477 6.85593C37.5917 4.86396 36.0797 3.24397 34.0757 3.24397H18.584" stroke="#283DC1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M22.3984 8.19922H36.7983" stroke="#283DC1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M33.9745 22.8542C33.9745 21.6709 33.0145 20.7109 31.8312 20.7109C30.648 20.7109 29.688 21.6709 29.688 22.8542C29.688 24.0374 30.648 24.9974 31.8312 24.9974C33.0145 24.9974 33.9745 24.0374 33.9745 22.8542Z" fill="#283DC1"/>
						<path d="M24.5755 22.8542C24.5755 21.6709 23.6155 20.7109 22.4323 20.7109C21.2491 20.7109 20.2891 21.6709 20.2891 22.8542C20.2891 24.0374 21.2491 24.9974 22.4323 24.9974C23.6155 24.9974 24.5755 24.0374 24.5755 22.8542Z" fill="#283DC1"/>
						<path d="M5.74449 11.3198H9V13.5358H5.74449V17H3.26453V13.5358H0V11.3198H3.26453V8H5.74449V11.3198Z" fill="#283DC1"/>
					</svg>

					<span>В корзину</span>
				</button>
			</div>

		</div>
	);
};

export const Favorite = () =>(
<svg className="product-izb" width="15" height="18" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M1.70538 1.03036C2.75093 0.283994 4.14516 0 5.61697 0H12.3862C13.9066 0 15.3097 0.305547 16.3483 1.09853C17.4227 1.91889 18 3.17799 18 4.82713V20.2476C18 21.6032 16.5512 22.4123 15.4095 21.7713L9.02651 18.178L2.58623 21.7783C1.44476 22.4165 0 21.606 0 20.2529V4.6325C0 3.01666 0.618563 1.80618 1.70538 1.03036ZM2.54532 2.23831C1.88673 2.70844 1.45836 3.4448 1.45836 4.6325V20.2529C1.45836 20.4399 1.67631 20.5994 1.88165 20.4846L8.67549 16.6866C8.89542 16.5636 9.16232 16.564 9.3819 16.6876L16.1162 20.4788C16.1161 20.4787 16.1164 20.4788 16.1162 20.4788C16.3233 20.5947 16.5416 20.4341 16.5416 20.2476V4.82713C16.5416 3.55787 16.1179 2.77281 15.4706 2.27862C14.7875 1.75705 13.7484 1.47764 12.3862 1.47764H5.61697C4.29484 1.47764 3.24518 1.73872 2.54532 2.23831Z" fill="#181829" fill-opacity="0.4"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M4.33594 7.87554C4.33594 7.4675 4.6624 7.13672 5.06512 7.13672H12.8557C13.2584 7.13672 13.5849 7.4675 13.5849 7.87554C13.5849 8.28358 13.2584 8.61436 12.8557 8.61436H5.06512C4.6624 8.61436 4.33594 8.28358 4.33594 7.87554Z" fill="#181829" fill-opacity="0.4"/>
</svg>

)

export const Recommendation = () =>{

	return(
		<>
			<h1 className="cards__title">Рекомендуем также</h1>
			<div className="cards">
				<div className="card spons">
					<span>Поиск спонсоров</span>
					<img className="card__img" src="/images/icons/money.png" alt=""/>
				</div>
				<div className="card ispol">
					<span>Поиск Исполнителей</span>
					<img className="card__img" src="https://s3-alpha-sig.figma.com/img/922e/3e7e/e61e711a8f9c7da4d3160bdad044d6c4?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=B29sC2GsjrowVOJ0Krg9bpzBolU8zlcJ3HLgwvT4fwJgwm~eDtYq3DR5Qk18wjz9-ufJ854RGsek~NITwIYYqjut0zXeFhFruemOQTHNdYuXTZSCCONFHqx4Hy7wHhLGtVlgVPiiGpkuGoZr6zRoAx41RUd9f0eNQr6lZnqCbf4mlHVTy6GlhcXBqu-EN0oPZmrFojyVQabxOSm4MlFUGvHDeisHrYQhy1C24FOB4USmeCWh0U5KfyK3LQvak8RpP3LtR6OGE0~fwoAPMEzKyHSsQX5-oqYZ81q1Sl7o2MtiTHn4HoLLMYJMUzBhffNlcbsn4bbOXt42vyiFJQDXGw__" alt=""/>
				</div>
			</div>
		</>
	)
};

export const Switch = ({SwitchName}) =>{

	return(
		<label className="switch-label">
			<span style={{ fontWeight: 700 }}>{SwitchName}</span>
			<label className="switch">
				<input
					type="checkbox"
					className="hidden"
					checked={""}
					onChange={""}
				/>
				<span className="slider round"></span>
			</label>
		</label>
	)
};

export const ListProductGrid = ({ products, itemsPerPage, cardsPerRow }) => {
	const [currentPage, setCurrentPage] = useState(1); // Текущая страница
	const [loadedProducts, setLoadedProducts] = useState(
		mainpg.slice(0, itemsPerPage)
	); // Загруженные товары
	const totalPages = Math.ceil(mainpg.length / itemsPerPage);

	// Загрузить дополнительные товары
	const handleLoadMore = () => {
		const nextProducts = mainpg.slice(
			loadedProducts.length,
			loadedProducts.length + itemsPerPage
		);
		setLoadedProducts((prevProducts) => [...prevProducts, ...nextProducts]);
	};

	// Переключение страницы
	const handlePageChange = (pageNumber) => {
		const startIndex = (pageNumber - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		setLoadedProducts(mainpg.slice(startIndex, endIndex)); // Загрузка товаров для выбранной страницы
		setCurrentPage(pageNumber); // Устанавливаем текущую страницу
		window.scrollTo({ top: 0, behavior: "smooth" }); // Скролл вверх
	};

	return (
		<div className="product-grid-container">
			{/* Сетка товаров с динамическим количеством карточек в строке */}
			<div
				className="product-grid"
				style={{
					gridTemplateColumns: `repeat(${cardsPerRow}, 1fr)`, // Устанавливаем количество карточек в строке
				}}
			>
				{loadedProducts.length > 0 ? (
					loadedProducts.map((product, index) => (
						<div className="product-item" key={index}>
							<ProductScrollLine cardsPerRow={cardsPerRow} product={product} />
						</div>
					))
				) : (
					<div className="no-products">
						<p>По выбранным фильтрам объявлений нет</p>
					</div>
				)}
			</div>

			<div className="pagination-container">
				{totalPages > 1 && (
					<div className="pagination">
						<img
							src={currentPage === 1
								? "/images/icons/next__gray.svg"
								: "/images/icons/next.svg"}
							className={`page-button btn__back ${currentPage === totalPages ? "disable" : ""}`}
							onClick={() => handlePageChange(1)}
							disabled={currentPage === 1}
						/>


						{Array.from({ length: totalPages }, (_, pageIndex) => pageIndex + 1).map(
							(page) => (
								<button
									key={page}
									className={`page-button ${currentPage === page ? "active" : ""}`}
									onClick={() => handlePageChange(page)}
								>
									{page}
								</button>
							)
						)}

						<img
							src={currentPage === totalPages
								? "/images/icons/next__gray.svg"
								: "/images/icons/next.svg"}
							className={`page-button btn__next ${currentPage === totalPages ? "disable" : ""}`}
							onClick={() => handlePageChange(totalPages)}
							disabled={currentPage === totalPages }
						/>
					</div>
				)}

				{/* Кнопка загрузки дополнительных товаров */}
				{currentPage < totalPages && (
					<button className="load-more-button" onClick={handleLoadMore}>
						Загрузить еще
					</button>
				)}
			</div>

		</div>
	);
};

export const PcVersion = () =>{
	const [selectedFormat, setSelectedFormat] = useState("four-view");
	const [itemsPerPage, setItemsPerPage] = useState(12);
	const [cardsPerRow, setCardsPerRow] = useState(4);


	return(
		<div className="container pc">
			<Header/>


			<main className="main__productList">
				<div className="leftside__productList">
					<FilterMenu/>
				</div>

				<div className="rightside__productList">
					<HeadListProduct selectedFormat={selectedFormat} setSelectedFormat={setSelectedFormat} setItemsPerPage={setItemsPerPage} setCardsPerRow={setCardsPerRow}/>
					<ListProductGrid itemsPerPage={itemsPerPage} cardsPerRow={cardsPerRow}/>
					<Recommendation/>
					<EtcListProduct/>
				</div>
			</main>
		</div>
	)
};


////////////////////////////////////////

const SearchBar = ({ searchQuery, setSearchQuery, clearSearch }) => (
	<div className="step step-2">
		<svg width="18" height="19" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893C9.09763 0.683417 9.09763 1.31658 8.70711 1.70711L3.414 7H17C17.5128 7 17.9355 7.38604 17.9933 7.88338L18 8C18 8.55228 17.5523 9 17 9H3.414L8.70711 14.2929C9.06759 14.6534 9.09532 15.2206 8.7903 15.6129L8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292893 8.70711L0.219689 8.62545C0.217372 8.62256 0.215071 8.61966 0.212786 8.61675C0.207285 8.60984 0.201753 8.6026 0.196334 8.59531C0.17849 8.57113 0.161719 8.54627 0.146068 8.52066C0.138607 8.50861 0.131499 8.49639 0.124671 8.48406C0.113794 8.46429 0.103377 8.44389 0.0936537 8.4231C0.0856789 8.4061 0.0781966 8.38879 0.0712256 8.37134C0.0633159 8.35158 0.0561225 8.3318 0.0495467 8.31174C0.0447288 8.29685 0.0400979 8.28146 0.0358453 8.26599C0.0298338 8.24444 0.0246396 8.22274 0.020165 8.20079C0.016702 8.18337 0.0136281 8.16595 0.0110178 8.14847C0.00376119 8.10036 0 8.05062 0 8L0.00396633 8.08925C0.0018949 8.06599 0.000634706 8.04268 0.000185966 8.01935L0 8C0 7.99359 6.03044e-05 7.9872 0.000180244 7.98082C0.000599384 7.95798 0.00186552 7.93433 0.00396633 7.91075C0.00576604 7.89015 0.00811212 7.8705 0.0110192 7.85104C0.013628 7.83405 0.0167024 7.81662 0.0202403 7.79927C0.02464 7.77725 0.0298335 7.75556 0.0357208 7.73416C0.0400976 7.71854 0.0447286 7.70315 0.0497379 7.68786C0.0561223 7.6682 0.0633158 7.64842 0.071104 7.62894C0.0781965 7.6112 0.0856789 7.5939 0.0936732 7.57678C0.103377 7.55611 0.113794 7.53571 0.124876 7.51572C0.131499 7.50361 0.138607 7.49139 0.145996 7.47929C0.161719 7.45372 0.17849 7.42887 0.196313 7.40484C0.225313 7.36567 0.257499 7.32829 0.292893 7.29289L0.212786 7.38325C0.237669 7.35153 0.264427 7.32136 0.292893 7.29289L7.29289 0.292893Z" fill="#3C3C3C"/>
		</svg>



		<label className="search__container">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={searchQuery ? "search-icon hide" : "search-icon"}>
				<path d="M19 19L13 13M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="#818189" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>

			<input
				type="text"
				placeholder="Поиск"
				className={searchQuery ? "search-input hide" : "search-input"}
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
			/>
			<svg className={searchQuery ? "clear-button visible" : "clear-button"} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="10" cy="10" r="10" fill="#DADADA"/>
				<path d="M6 6L14 14M6 14L14 6" stroke="#818189" stroke-width="1.5"/>
			</svg>

		</label>
		<svg width="34" height="30" viewBox="0 0 34 30" className={searchQuery ? "clear-button hide" : "icon-btn favorite"} fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="34" height="30" rx="12" fill="#EAEAEA"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M23.6111 9.98712C23.6111 7.61708 21.9637 6.66699 19.5919 6.66699H14.0219C11.7229 6.66699 10 7.5523 10 9.82906V22.5145C10 23.1398 10.6841 23.5337 11.2382 23.2279L16.8284 20.1436L22.3703 23.2227C22.9253 23.5302 23.6111 23.1364 23.6111 22.5102V9.98712Z" stroke="#818189" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M13.5684 12.4631H19.9788" stroke="#818189" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
		<svg width="34" height="30" viewBox="0 0 34 30" className={searchQuery ? "clear-button hide" : "icon-btn cart"} fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="34" height="30" rx="12" fill="#EAEAEA"/>
			<path d="M8 6.66699H9.479C10.397 6.66699 11.1195 7.44199 11.043 8.33366L10.3375 16.6337C10.2185 17.992 11.315 19.1587 12.709 19.1587H21.7615C22.9855 19.1587 24.0565 18.1753 24.15 16.9837L24.609 10.7337C24.711 9.35033 23.64 8.22532 22.2205 8.22532H11.247" stroke="#818189" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M13.95 11.667H24.15" stroke="#818189" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M22.15 21.8448C22.15 21.0231 21.47 20.3564 20.6319 20.3564C19.7937 20.3564 19.1137 21.0231 19.1137 21.8448C19.1137 22.6665 19.7937 23.3332 20.6319 23.3332C21.47 23.3332 22.15 22.6665 22.15 21.8448Z" fill="#818189"/>
			<path d="M15.4923 21.8448C15.4923 21.0231 14.8123 20.3564 13.9742 20.3564C13.1361 20.3564 12.4561 21.0231 12.4561 21.8448C12.4561 22.6665 13.1361 23.3332 13.9742 23.3332C14.8123 23.3332 15.4923 22.6665 15.4923 21.8448Z" fill="#818189"/>
		</svg>

		<p className={searchQuery ? "clear-text visible" : "clear-text"}>Вернуться</p>
	</div>
);

const Modal = ({ isOpen, children, onClose, onClear,
				   hasSelection, }) => {
	if (!isOpen) return null;

	const handleBackdropClick = (e) => {
		if (e.target.classList.contains("modal")) {
			onClose();
		}
	};

	return (
		<div className="modal" onClick={handleBackdropClick}>
			<div className="modal-content">
				{children}

				<button className={`apply-btn gray ${hasSelection ? "active" : ""}`} style={{borderRadius:99}} onClick={onClear}>
					Очистить фильтр
				</button>
				<button className="apply-btn" style={{borderRadius:99}} onClick={onClose}>
					Применить
				</button>
			</div>
		</div>
	);
};

const OneBtnModal = ({ isOpen, children, onClose, onClear }) => {
	if (!isOpen) return null;
	const handleBackdropClick = (e) => {
		if (e.target.classList.contains("modal")) {
			onClose();
		}
	};

	return (
		<div className="modal" onClick={handleBackdropClick}>
			<div className="modal-content">
				{children}

				<button className="apply-btn" style={{borderRadius:99, marginBottom:0}} onClick={onClose}>
					Закрыть
				</button>
			</div>
		</div>
	);
};

const ViewModeSelector = ({ viewMode, setViewMode, children, onClose}) => {
	return(
			<>
				<div className="modal-header">
					<svg width="18" onClick={onClose} height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893C9.09763 0.683417 9.09763 1.31658 8.70711 1.70711L3.414 7H17C17.5128 7 17.9355 7.38604 17.9933 7.88338L18 8C18 8.55228 17.5523 9 17 9H3.414L8.70711 14.2929C9.06759 14.6534 9.09532 15.2206 8.7903 15.6129L8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292893 8.70711L0.219689 8.62545C0.217372 8.62256 0.215071 8.61966 0.212786 8.61675C0.207285 8.60984 0.201753 8.6026 0.196334 8.59531C0.17849 8.57113 0.161719 8.54627 0.146068 8.52066C0.138607 8.50861 0.131499 8.49639 0.124671 8.48406C0.113794 8.46429 0.103377 8.44389 0.0936537 8.4231C0.0856789 8.4061 0.0781966 8.38879 0.0712256 8.37134C0.0633159 8.35158 0.0561225 8.3318 0.0495467 8.31174C0.0447288 8.29685 0.0400979 8.28146 0.0358453 8.26599C0.0298338 8.24444 0.0246396 8.22274 0.020165 8.20079C0.016702 8.18337 0.0136281 8.16595 0.0110178 8.14847C0.00376119 8.10036 0 8.05062 0 8L0.00396633 8.08925C0.0018949 8.06599 0.000634706 8.04268 0.000185966 8.01935L0 8C0 7.99359 6.03044e-05 7.9872 0.000180244 7.98082C0.000599384 7.95798 0.00186552 7.93433 0.00396633 7.91075C0.00576604 7.89015 0.00811212 7.8705 0.0110192 7.85104C0.013628 7.83405 0.0167024 7.81662 0.0202403 7.79927C0.02464 7.77725 0.0298335 7.75556 0.0357208 7.73416C0.0400976 7.71854 0.0447286 7.70315 0.0497379 7.68786C0.0561223 7.6682 0.0633158 7.64842 0.071104 7.62894C0.0781965 7.6112 0.0856789 7.5939 0.0936732 7.57678C0.103377 7.55611 0.113794 7.53571 0.124876 7.51572C0.131499 7.50361 0.138607 7.49139 0.145996 7.47929C0.161719 7.45372 0.17849 7.42887 0.196313 7.40484C0.225313 7.36567 0.257499 7.32829 0.292893 7.29289L0.212786 7.38325C0.237669 7.35153 0.264427 7.32136 0.292893 7.29289L7.29289 0.292893Z" fill="#3C3C3C"/>
					</svg>


					<h2 className="modal-title">Формат отображения</h2>
				</div>

				<div className="modal-menu-content">
					{["grid", "solo", "list"].map((mode) => (
						<div className="modal-stroke" key={mode}>
							<input
								type="radio"
								id={`${mode}-view`}
								name="viewMode"
								value={mode}
								checked={viewMode === mode}
								onChange={() => setViewMode(mode)}
							/>
							<label htmlFor={`${mode}-view`}>{mode === "grid" ? "Сеткой" : mode === "solo" ? "По одному" : "Вертикально"}</label>
						</div>
					))}
				</div>
			</>
	)
}

const SortModal = ({isOpen, sortType, onClose, onSortChange, onClear, onApply,}) => {
	if (!isOpen) return null;

	return (
		<>
			<div className="modal-header">
				<svg width="18" onClick={onClose} height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893C9.09763 0.683417 9.09763 1.31658 8.70711 1.70711L3.414 7H17C17.5128 7 17.9355 7.38604 17.9933 7.88338L18 8C18 8.55228 17.5523 9 17 9H3.414L8.70711 14.2929C9.06759 14.6534 9.09532 15.2206 8.7903 15.6129L8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292893 8.70711L0.219689 8.62545C0.217372 8.62256 0.215071 8.61966 0.212786 8.61675C0.207285 8.60984 0.201753 8.6026 0.196334 8.59531C0.17849 8.57113 0.161719 8.54627 0.146068 8.52066C0.138607 8.50861 0.131499 8.49639 0.124671 8.48406C0.113794 8.46429 0.103377 8.44389 0.0936537 8.4231C0.0856789 8.4061 0.0781966 8.38879 0.0712256 8.37134C0.0633159 8.35158 0.0561225 8.3318 0.0495467 8.31174C0.0447288 8.29685 0.0400979 8.28146 0.0358453 8.26599C0.0298338 8.24444 0.0246396 8.22274 0.020165 8.20079C0.016702 8.18337 0.0136281 8.16595 0.0110178 8.14847C0.00376119 8.10036 0 8.05062 0 8L0.00396633 8.08925C0.0018949 8.06599 0.000634706 8.04268 0.000185966 8.01935L0 8C0 7.99359 6.03044e-05 7.9872 0.000180244 7.98082C0.000599384 7.95798 0.00186552 7.93433 0.00396633 7.91075C0.00576604 7.89015 0.00811212 7.8705 0.0110192 7.85104C0.013628 7.83405 0.0167024 7.81662 0.0202403 7.79927C0.02464 7.77725 0.0298335 7.75556 0.0357208 7.73416C0.0400976 7.71854 0.0447286 7.70315 0.0497379 7.68786C0.0561223 7.6682 0.0633158 7.64842 0.071104 7.62894C0.0781965 7.6112 0.0856789 7.5939 0.0936732 7.57678C0.103377 7.55611 0.113794 7.53571 0.124876 7.51572C0.131499 7.50361 0.138607 7.49139 0.145996 7.47929C0.161719 7.45372 0.17849 7.42887 0.196313 7.40484C0.225313 7.36567 0.257499 7.32829 0.292893 7.29289L0.212786 7.38325C0.237669 7.35153 0.264427 7.32136 0.292893 7.29289L7.29289 0.292893Z" fill="#3C3C3C"/>
				</svg>
				<h2 className="modal-title">Сортировка</h2>
			</div>
			<div className="modal-menu-content">
					{[
						{ id: "price-asc", label: "Сначала дешевле" },
						{ id: "price-desc", label: "Сначала дороже" },
						{ id: "date", label: "По дате" },
						{ id: "rating", label: "По рейтингу" },
					].map((option) => (
						<div key={option.id} className="modal-stroke">
							<input
								type="radio"
								id={option.id}
								name="sortType"
								value={option.id}
								checked={sortType === option.id}
								onChange={(e) => onSortChange(e.target.value)}
							/>
							<label htmlFor={option.id}>{option.label}</label>
						</div>
					))}
			</div>
		</>
	);
};

const Age = ({isOpen, onClose,ageGroups, onClear, onApply, selectedAgeGroup, setSelectedAgeGroup,}) => {
	if (!isOpen) return null;

	return (
		<>
			<div className="modal-header">
				<svg width="18" onClick={onClose} height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893C9.09763 0.683417 9.09763 1.31658 8.70711 1.70711L3.414 7H17C17.5128 7 17.9355 7.38604 17.9933 7.88338L18 8C18 8.55228 17.5523 9 17 9H3.414L8.70711 14.2929C9.06759 14.6534 9.09532 15.2206 8.7903 15.6129L8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292893 8.70711L0.219689 8.62545C0.217372 8.62256 0.215071 8.61966 0.212786 8.61675C0.207285 8.60984 0.201753 8.6026 0.196334 8.59531C0.17849 8.57113 0.161719 8.54627 0.146068 8.52066C0.138607 8.50861 0.131499 8.49639 0.124671 8.48406C0.113794 8.46429 0.103377 8.44389 0.0936537 8.4231C0.0856789 8.4061 0.0781966 8.38879 0.0712256 8.37134C0.0633159 8.35158 0.0561225 8.3318 0.0495467 8.31174C0.0447288 8.29685 0.0400979 8.28146 0.0358453 8.26599C0.0298338 8.24444 0.0246396 8.22274 0.020165 8.20079C0.016702 8.18337 0.0136281 8.16595 0.0110178 8.14847C0.00376119 8.10036 0 8.05062 0 8L0.00396633 8.08925C0.0018949 8.06599 0.000634706 8.04268 0.000185966 8.01935L0 8C0 7.99359 6.03044e-05 7.9872 0.000180244 7.98082C0.000599384 7.95798 0.00186552 7.93433 0.00396633 7.91075C0.00576604 7.89015 0.00811212 7.8705 0.0110192 7.85104C0.013628 7.83405 0.0167024 7.81662 0.0202403 7.79927C0.02464 7.77725 0.0298335 7.75556 0.0357208 7.73416C0.0400976 7.71854 0.0447286 7.70315 0.0497379 7.68786C0.0561223 7.6682 0.0633158 7.64842 0.071104 7.62894C0.0781965 7.6112 0.0856789 7.5939 0.0936732 7.57678C0.103377 7.55611 0.113794 7.53571 0.124876 7.51572C0.131499 7.50361 0.138607 7.49139 0.145996 7.47929C0.161719 7.45372 0.17849 7.42887 0.196313 7.40484C0.225313 7.36567 0.257499 7.32829 0.292893 7.29289L0.212786 7.38325C0.237669 7.35153 0.264427 7.32136 0.292893 7.29289L7.29289 0.292893Z" fill="#3C3C3C"/>
				</svg>
				<h2 className="modal-title">Возраст</h2>
			</div>
			<div className="modal-menu-content">
				{ageGroups.map((group) => (
					<div
						key={group}
						style={{ flexDirection: "row" }}
						className="modal-stroke">
						<input
							type="radio"
							id={group}
							name="ageGroup"
							value={group}
							checked={selectedAgeGroup === group}
							onChange={(e) => setSelectedAgeGroup(e.target.value)}
						/>
						<label htmlFor={group}>{group}</label>
					</div>
				))}
			</div>
		</>
	);
};

const CitySelector = ({ selectedCity, cities, handleCitySelect, cityDropdownOpen, setCityDropdownOpen }) => (
	<div className="step step-3">
		<div className="custom-select">
			<span className="city"><img style={{width: 11, height:14, objectFit: "cover"}} src="/images/icons/geo.svg" alt=""/>
<p>{selectedCity}</p></span>
			<button className="city city-button" onClick={() => setCityDropdownOpen(!cityDropdownOpen)}>
				<svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.8889C0 10.4593 0.27467 10.1111 0.613493 10.1111H7.11378C7.4526 10.1111 7.72727 10.4593 7.72727 10.8889C7.72727 11.3184 7.4526 11.6666 7.11378 11.6666H0.613493C0.27467 11.6666 0 11.3184 0 10.8889Z" fill="#3C3C3C"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M9.27246 3.11079C9.27246 2.68123 9.54713 2.33301 9.88595 2.33301H16.3862C16.7251 2.33301 16.9997 2.68123 16.9997 3.11079C16.9997 3.54034 16.7251 3.88856 16.3862 3.88856H9.88595C9.54713 3.88856 9.27246 3.54034 9.27246 3.11079Z" fill="#3C3C3C"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.11111C0 1.39354 1.3835 0 3.09091 0C4.79832 0 6.18182 1.39354 6.18182 3.11111C6.18182 4.82868 4.79832 6.22222 3.09091 6.22222C1.3835 6.22222 0 4.82868 0 3.11111ZM3.09091 1.25011C2.07089 1.25011 1.24284 2.08296 1.24284 3.11111C1.24284 4.13926 2.07089 4.97211 3.09091 4.97211C4.11092 4.97211 4.93898 4.13926 4.93898 3.11111C4.93898 2.08296 4.11092 1.25011 3.09091 1.25011Z" fill="#3C3C3C"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M10.8184 10.8885C10.8184 9.17075 12.202 7.77734 13.9101 7.77734C15.6178 7.77734 17.0002 9.17117 17.0002 10.8885C17.0002 12.6057 15.6178 13.9996 13.9101 13.9996C12.202 13.9996 10.8184 12.6062 10.8184 10.8885ZM13.9101 9.02746C12.8891 9.02746 12.0612 9.86045 12.0612 10.8885C12.0612 11.9165 12.8891 12.7495 13.9101 12.7495C14.9299 12.7495 15.7573 11.9169 15.7573 10.8885C15.7573 9.86003 14.9299 9.02746 13.9101 9.02746Z" fill="#3C3C3C"/>
				</svg>
				<p>Выбрать город</p>
			</button>
			{cityDropdownOpen && (
				<div className="dropdown">
					{cities.map((city) => (
						<button key={city} onClick={() => handleCitySelect(city)}>
							{city}
						</button>
					))}
				</div>
			)}
		</div>
	</div>
);

const SelectAge = ({selectedAgeGroup, setSelectedAgeGroup, openAgeModal}) => (
	<div
		className={`filter age ${selectedAgeGroup ? "active" : ""}`}
		onClick={openAgeModal}>
		{selectedAgeGroup ? selectedAgeGroup : "Возраст"} <p></p>
	</div>
)

const PriceFilterItem = ({openPriceModal, priceRange, setPriceRange }) => {
	return(
		<div className="filter" onClick={openPriceModal}>
			Цена {priceRange.min ? `от: ${priceRange.min}` : ""}{" "}
			{priceRange.max ? `до ${priceRange.max}` : ""}
		</div>
	)
};

const PriceFilterModalItem = ({priceRange, setPriceRange}) =>(
	<>
		<label style={{ fontWeight: 700 }}>Стоимость, ₽</label>
		<div className="price-range">
			<label htmlFor="">
				<span>От</span>
				<input
					type="number"
					value={priceRange.min}
					onChange={(e) =>
						setPriceRange({ ...priceRange, min: e.target.value })
					}
					placeholder="0"
				/>
			</label>

			<label>
				<span>До</span>
				<input
					type="number"
					value={priceRange.max}
					onChange={(e) =>
						setPriceRange({ ...priceRange, max: e.target.value })
					}
					placeholder="45000"
				/>
			</label>
		</div>
		<div className="price-slider-container">
			<input
				type="range"
				min="0"
				max="22500"
				value={priceRange.min}
				className="price-slider"
			/>
			<input
				type="range"
				min="22500"
				max="45000"
				value={priceRange.max}
				className="price-slider"
			/>
		</div>
	</>
)

const Filterss = ({getActiveFiltersCount, openFilterModal,selectedAgeGroup, setSelectedAgeGroup, openAgeModal, openModal,category,selectedCategory,handleCategoryClick,priceRange,openPriceModal,selectedCondition,openConditionModal,children}) => (
	<div className="step step-4">
		<div className="filters">
			  <span
				  className={`filter-button ${getActiveFiltersCount() > 0 ? "active" : ""}`} onClick={openFilterModal}>
				              {getActiveFiltersCount() < 1 ? (
								  <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 14C0 13.4477 0.355455 13 0.793932 13H9.20607C9.64454 13 10 13.4477 10 14C10 14.5523 9.64454 15 9.20607 15H0.793932C0.355455 15 0 14.5523 0 14Z" fill="#4C4C4C"/>
									  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C12 3.44772 12.3555 3 12.7939 3H21.2061C21.6445 3 22 3.44772 22 4C22 4.55228 21.6445 5 21.2061 5H12.7939C12.3555 5 12 4.55228 12 4Z" fill="#4C4C4C"/>
									  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4C0 1.7917 1.79041 0 4 0C6.20959 0 8 1.7917 8 4C8 6.2083 6.20959 8 4 8C1.79041 8 0 6.2083 0 4ZM4 1.60729C2.67998 1.60729 1.60838 2.6781 1.60838 4C1.60838 5.3219 2.67998 6.39271 4 6.39271C5.32002 6.39271 6.39162 5.3219 6.39162 4C6.39162 2.6781 5.32002 1.60729 4 1.60729Z" fill="#4C4C4C"/>
									  <path fill-rule="evenodd" clip-rule="evenodd" d="M14 14C14 11.7915 15.7906 10 18.0011 10C20.211 10 22 11.7921 22 14C22 16.2079 20.211 18 18.0011 18C15.7906 18 14 16.2085 14 14ZM18.0011 11.6073C16.6798 11.6073 15.6084 12.6783 15.6084 14C15.6084 15.3217 16.6798 16.3927 18.0011 16.3927C19.3208 16.3927 20.3916 15.3223 20.3916 14C20.3916 12.6777 19.3208 11.6073 18.0011 11.6073Z" fill="#4C4C4C"/>
								  </svg>

							  ) : (
								  <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 14C0 13.4477 0.355455 13 0.793932 13H9.20607C9.64454 13 10 13.4477 10 14C10 14.5523 9.64454 15 9.20607 15H0.793932C0.355455 15 0 14.5523 0 14Z" fill="white"/>
									  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C12 3.44772 12.3555 3 12.7939 3H21.2061C21.6445 3 22 3.44772 22 4C22 4.55228 21.6445 5 21.2061 5H12.7939C12.3555 5 12 4.55228 12 4Z" fill="white"/>
									  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4C0 1.7917 1.79041 0 4 0C6.20959 0 8 1.7917 8 4C8 6.2083 6.20959 8 4 8C1.79041 8 0 6.2083 0 4ZM4 1.60729C2.67998 1.60729 1.60838 2.6781 1.60838 4C1.60838 5.3219 2.67998 6.39271 4 6.39271C5.32002 6.39271 6.39162 5.3219 6.39162 4C6.39162 2.6781 5.32002 1.60729 4 1.60729Z" fill="white"/>
									  <path fill-rule="evenodd" clip-rule="evenodd" d="M14 14C14 11.7915 15.7906 10 18.0011 10C20.211 10 22 11.7921 22 14C22 16.2079 20.211 18 18.0011 18C15.7906 18 14 16.2085 14 14ZM18.0011 11.6073C16.6798 11.6073 15.6084 12.6783 15.6084 14C15.6084 15.3217 16.6798 16.3927 18.0011 16.3927C19.3208 16.3927 20.3916 15.3223 20.3916 14C20.3916 12.6777 19.3208 11.6073 18.0011 11.6073Z" fill="white"/>
								  </svg>

							  )}

				{getActiveFiltersCount() > 0 && <span className="filter-count">{getActiveFiltersCount()}</span>}
			  </span>

			<SelectAge openAgeModal={openAgeModal} selectedAgeGroup={selectedAgeGroup} setSelectedAgeGroup={setSelectedAgeGroup}/>

			{category.map((cat) => (
				<div
					key={cat}
					className={`filter ${selectedCategory.includes(cat) ? "active" : ""}`}
					onClick={() => handleCategoryClick(cat)}>
					{cat}
				</div>
			))}

			<PriceFilterItem priceRange={priceRange}/>
		</div>
	</div>
);

const PriceFilterModal = ({isPriceModalOpen, closePriceModal, priceRange, setPriceRange, handleApplyPriceFilter,}) => (
	<Modal isOpen={isPriceModalOpen} onClose={closePriceModal}>
		<h2>Цена</h2>
		<div>
			<label>Цена от:</label>
			<input
				type="number"
				value={priceRange.min}
				onChange={(e) => setPriceRange({ ...priceRange, min: e.target.value })}
			/>
		</div>
		<div>
			<label>Цена до:</label>
			<input
				type="number"
				value={priceRange.max}
				onChange={(e) => setPriceRange({ ...priceRange, max: e.target.value })}
			/>
		</div>
		<button onClick={handleApplyPriceFilter}>Применить фильтр</button>
	</Modal>
);

const ConditionModal = ({isOpen, onClose, conditions, selectedCondition, onSelectCondition, onClear, onApply,}) => {
	if (!isOpen) return null;

	return (
		<div className="modal">
			<div className="modal-content">
				<div className="modal-header">
					<button className="back-button" onClick={onClose}></button>
					<h2>Состояние</h2>
					<button className="clear-btn" onClick={onClear}>
						Очистить
					</button>
				</div>
				<div className="modal-body">
					{conditions.map((condition) => (
						<div key={condition} className="radio-group">
							<input
								type="radio"
								id={condition}
								name="condition"
								value={condition}
								checked={selectedCondition === condition}
								onChange={(e) => onSelectCondition(e.target.value)}
							/>
							<label htmlFor={condition}>{condition}</label>
						</div>
					))}
				</div>
				<div className="modal-footer">
					<button className="apply-btn" onClick={onApply}>
						Применить фильтр
					</button>
				</div>
			</div>
		</div>
	);
};

const FilterModal = ({isOpen, onClose,selectedCity,onCitySelect,cities,priceRange,setPriceRange,category,selectedCategory,onCategoryClick,selectedCondition,onConditionSelect,onApply,onReset, closeFilterModal}) => {
	const [cityDropdownOpen, setCityDropdownOpen] = useState(false);
	const SelectFilterName1 = ("Вратарь");
	if (!isOpen) return null;

	return (
		<div className="modal modal-fullscreen">
			<div className="modal-content modal-content-filter">
				<div className="modal-header">
					<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={closeFilterModal}>
						<path d="M7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893C9.09763 0.683417 9.09763 1.31658 8.70711 1.70711L3.414 7H17C17.5128 7 17.9355 7.38604 17.9933 7.88338L18 8C18 8.55228 17.5523 9 17 9H3.414L8.70711 14.2929C9.06759 14.6534 9.09532 15.2206 8.7903 15.6129L8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292893 8.70711L0.219689 8.62545C0.217372 8.62256 0.215071 8.61966 0.212786 8.61675C0.207285 8.60984 0.201753 8.6026 0.196334 8.59531C0.17849 8.57113 0.161719 8.54627 0.146068 8.52066C0.138607 8.50861 0.131499 8.49639 0.124671 8.48406C0.113794 8.46429 0.103377 8.44389 0.0936537 8.4231C0.0856789 8.4061 0.0781966 8.38879 0.0712256 8.37134C0.0633159 8.35158 0.0561225 8.3318 0.0495467 8.31174C0.0447288 8.29685 0.0400979 8.28146 0.0358453 8.26599C0.0298338 8.24444 0.0246396 8.22274 0.020165 8.20079C0.016702 8.18337 0.0136281 8.16595 0.0110178 8.14847C0.00376119 8.10036 0 8.05062 0 8L0.00396633 8.08925C0.0018949 8.06599 0.000634706 8.04268 0.000185966 8.01935L0 8C0 7.99359 6.03044e-05 7.9872 0.000180244 7.98082C0.000599384 7.95798 0.00186552 7.93433 0.00396633 7.91075C0.00576604 7.89015 0.00811212 7.8705 0.0110192 7.85104C0.013628 7.83405 0.0167024 7.81662 0.0202403 7.79927C0.02464 7.77725 0.0298335 7.75556 0.0357208 7.73416C0.0400976 7.71854 0.0447286 7.70315 0.0497379 7.68786C0.0561223 7.6682 0.0633158 7.64842 0.071104 7.62894C0.0781965 7.6112 0.0856789 7.5939 0.0936732 7.57678C0.103377 7.55611 0.113794 7.53571 0.124876 7.51572C0.131499 7.50361 0.138607 7.49139 0.145996 7.47929C0.161719 7.45372 0.17849 7.42887 0.196313 7.40484C0.225313 7.36567 0.257499 7.32829 0.292893 7.29289L0.212786 7.38325C0.237669 7.35153 0.264427 7.32136 0.292893 7.29289L7.29289 0.292893Z" fill="#3C3C3C"/>
					</svg>

					<h2 className="modal-title">Фильтры</h2>
					<a href="#" onClick={onReset} className="clear-btn" style={{ textDecoration: "none" }}>
						Очистить
					</a>
				</div>

				<div className="modal-body">
					<SelectFilter SelectFilterName1={SelectFilterName1}/>

					{/* Выбор города */}
					<label style={{ fontWeight: 700 }}>Выбрать город</label>
					<div className="filter-group">
						<div className="custom-select">
							<div className="dropdown-container">
								<div
									className="dropdown-trigger"
									onClick={() => setCityDropdownOpen(!cityDropdownOpen)}>
									<span className="dropdown-label">{selectedCity}</span>
									<span className="dropdown-value" style={{ rotate: "" }}>

                  </span>
								</div>
							</div>
							{cityDropdownOpen && (
								<div className="dropdown">
									{cities.map((city) => (
										<button key={city} onClick={() => onCitySelect(city)}>
											{city}
										</button>
									))}
								</div>
							)}
						</div>
					</div>

					{/* Фильтрация по цене */}
					<PriceFilterModalItem priceRange={priceRange}/>

					{/* Категории */}
					<div className="filter-group">
						{category.map((cat) => (
							<div key={cat} className="switch-group">
								<label className="switch-label">
									<span>{cat}</span>
									<label className="switch">
										<input
											type="checkbox"
											checked={selectedCategory.includes(cat)}
											onChange={() => onCategoryClick(cat)}
										/>
										<span className="slider round"></span>
									</label>
								</label>
							</div>
						))}
					</div>

					<button
						className="apply-btn"
						onClick={onApply}>
						Применить
					</button>
				</div>
			</div>
		</div>
	);
};

const ViewMode = ({openSortModal, sortType, openModalOtobr, viewMode}) => {
	const renderIcon = () => {
		switch (viewMode) {
			case "grid":
				return (
					<svg width="14" height="14" style={{marginRight: 8}} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.66668 1H2.00001C1.44773 1 1.00001 1.44772 1.00001 2L1 5.66667C1 6.21895 1.44771 6.66667 2 6.66667H5.66666C6.21895 6.66667 6.66666 6.21895 6.66666 5.66667L6.66668 2C6.66668 1.44772 6.21896 1 5.66668 1Z" stroke="#3C3C3C" stroke-width="1.5"/>
						<path d="M14 1H10.3333C9.78104 1 9.33332 1.44772 9.33332 2V5.66667C9.33332 6.21895 9.78104 6.66667 10.3333 6.66667H14C14.5523 6.66667 15 6.21895 15 5.66667V2C15 1.44772 14.5523 1 14 1Z" stroke="#3C3C3C" stroke-width="1.5"/>
						<path d="M14 9.33333H10.3333C9.78104 9.33333 9.33332 9.78105 9.33332 10.3333V14C9.33332 14.5523 9.78104 15 10.3333 15H14C14.5523 15 15 14.5523 15 14V10.3333C15 9.78105 14.5523 9.33333 14 9.33333Z" stroke="#3C3C3C" stroke-width="1.5"/>
						<path d="M5.66666 9.33333H2.00001C1.44773 9.33333 1.00001 9.78105 1.00001 10.3333V14C1.00001 14.5523 1.44773 15 2.00001 15H5.66666C6.21895 15 6.66666 14.5523 6.66666 14V10.3333C6.66666 9.78105 6.21895 9.33333 5.66666 9.33333Z" stroke="#3C3C3C" stroke-width="1.5"/>
					</svg>
				);
			case "solo":
				return (
					<svg width="14" height="14" style={{marginRight: 8}} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect x="1.25" y="1.25" width="15.5" height="15.5" rx="2.75" stroke="#3C3C3C" stroke-width="1.5"/>
					</svg>


				);
			case "list":
				return (
					<svg width="14" height="14" style={{marginRight: 8}} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M7.22222 3.33333H15M7.22222 12.6667H15M2.18008 1H4.54023C5.19197 1 5.72031 1.52233 5.72031 2.16667V4.5C5.72031 5.14433 5.19197 5.66667 4.54023 5.66667H2.18008C1.52834 5.66667 1 5.14433 1 4.5V2.16667C1 1.52233 1.52834 1 2.18008 1ZM2.18008 10.3333H4.54023C5.19197 10.3333 5.72031 10.8557 5.72031 11.5V13.8333C5.72031 14.4777 5.19197 15 4.54023 15H2.18008C1.52834 15 1 14.4777 1 13.8333V11.5C1 10.8557 1.52834 10.3333 2.18008 10.3333Z" stroke="#3C3C3C" stroke-width="1.5"/>
					</svg>

				);
			default:
				return null;
		}
	};

	const sortOptions = {
		"price-asc": "Сначала дешевле",
		"price-desc": "Сначала дороже",
		date: "По дате",
		rating: "По рейтингу",
	};

	return(
		<div className="step step-5">
			<div className="custom-select">
				<button className="sort-button" onClick={openSortModal}>
					<img src="/images/icons/geo.svg" alt="" style={{width: 11, height: 17, objectFit: "cover", overflow: "visible"}}/>
					{sortOptions[sortType] || "Сортировка"}
				</button>
			</div>
			<button className="notify-button" onClick={openModalOtobr}>

				{renderIcon()}
				Формат отображения
			</button>
		</div>
)}

const TwoStepList = ({viewMode, currentSlides, settings, groupedProducts}) => (
	<div
		className={`product-scroll-container ${
			viewMode === "grid"
				? "grid-view"
				: viewMode === "list"
					? "list-view"
					: "solo-view"
		}`}>
		<div className="products-row">
			{groupedProducts.length > 0 ? (
				groupedProducts.map((group, index) => (
					<div className="product-column" key={index}>
						{group.map((product) => (
							<ProductScrollLine group={group} index={index} product={product}/>
						))}
					</div>
				))
			) : (
				<div className="no-products">
					<p>По выбранным фильтрам объявлений нет</p>
				</div>
			)}
		</div>
	</div>
)

const CityFilter = ({ cities, selectedCity, setSelectedCity }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="filter-group">
			<label style={{ fontWeight: 700 }} onClick={() => setIsOpen(!isOpen)}>Выбрать город</label>
			<div className="custom-select">
				<div className="dropdown-container">
					<div
						className="dropdown-trigger"

					>
						<span className="dropdown-label">{selectedCity}</span>
						<span className="dropdown-value" style={{ rotate: "90deg" }}>
              &gt;
            </span>
					</div>
				</div>
				{isOpen && (
					<div className="dropdown">
						{cities.map((city) => (
							<button key={city} onClick={() => setSelectedCity(city)}>
								{city}
							</button>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

const PriceFilter = ({ priceRange, setPriceRange }) => {
	return (
		<div className="filter-group">
			<label style={{ fontWeight: 700 }}>Стоимость</label>
			<div className="price-range">
				<input
					type="number"
					value={priceRange.min}
					onChange={(e) =>
						setPriceRange({ ...priceRange, min: e.target.value })
					}
					placeholder="От 0"
				/>
				<input
					type="number"
					value={priceRange.max}
					onChange={(e) =>
						setPriceRange({ ...priceRange, max: e.target.value })
					}
					placeholder="До 45000"
				/>
			</div>
			<div className="price-slider-container">
				<input
					type="range"
					min="0"
					max="45000"
					value={priceRange.min}
					onChange={(e) =>
						setPriceRange({
							...priceRange,
							min: Math.min(Number(e.target.value), priceRange.max - 1),
						})
					}
					className="price-slider"
				/>
				<input
					type="range"
					min="0"
					max="45000"
					value={priceRange.max}
					onChange={(e) =>
						setPriceRange({
							...priceRange,
							max: Math.max(Number(e.target.value), priceRange.min + 1),
						})
					}
					className="price-slider"
				/>
			</div>
		</div>
	);
};

const CategoryFilter = ({ category, selectedCategory, handleCategoryClick }) => {
	return (
		<div className="filter-group">
			<div className="radio-group">
				{category
					.filter((cat) => cat !== "Не выбрано")
					.map((cat) => (
						<div key={cat} className="switch-group">
							<label className="switch-label">
								<span style={{ fontWeight: 700 }}>{cat}</span>
								<label className="switch">
									<input
										type="checkbox"
										className="hidden"
										checked={selectedCategory.includes(cat)}
										onChange={() => handleCategoryClick(cat)}
									/>
									<span className="slider round"></span>
								</label>
							</label>
						</div>
					))}
			</div>
		</div>
	);
};

export const ConditionFilter = ({ selectedCondition, setSelectedCondition }) => (
	<div className="filter-group">
		<label style={{ fontWeight: 700 }}>Состояние</label>
		<div className="radio-group">
			<div className="form_radio">
				<input
					type="radio"
					id="new"
					name={"filed_" + "condition"}
					value="Новое"
					checked={selectedCondition === "Новое"}
					onChange={() => setSelectedCondition("Новое")}
				/>
				<label htmlFor="new">Новое</label>
			</div>

			<div>
				<input
					type="radio"
					id="used"
					name="condition"
					value="Б/У"
					checked={selectedCondition === "Б/У"}
					onChange={() => setSelectedCondition("Б/У")}
				/>
				<label htmlFor="used">Б/У</label>
			</div>
		</div>
	</div>
);

export const CompetitionTypeFilter = ({ floorGroups, selectedFloorGroup, setSelectedFloorGroup }) => (
	<div className="filter-group">
		<label style={{ fontWeight: 700 }}>Тип соревнования</label>
		<div className="custom-select">
			<div className="dropdown-container">
				<div
					className="dropdown-trigger"
					onClick={() => setSelectedFloorGroup(!selectedFloorGroup)}
				>
          <span className="dropdown-label">
            {selectedFloorGroup ? selectedFloorGroup : "Выберите тип соревнования"}
          </span>
				</div>
			</div>
			{selectedFloorGroup && (
				<div className="dropdown">
					{floorGroups.map((floor) => (
						<button key={floor} onClick={() => setSelectedFloorGroup(floor)}>
							{floor}
						</button>
					))}
				</div>
			)}
		</div>
	</div>
);

export const MobileListPage = () => {
	const [push, setPush] = useState(["arena", "school"]);
	const navigate = useNavigate();
	const handleNotification = async () => {
		const categoriesString = push.join(",");
		const url = `/api/user/setcategoriesnew?categoriesnew=${categoriesString}`;

		try {
			const response = await fetch(url, {
				method: "GET", // или 'POST' в зависимости от требований
			});

			if (response.ok) {
				console.log("Успешно отправлено!");
			} else {
				console.error("Ошибка при отправке уведомлений");
			}
		} catch (error) {
			console.error("Ошибка:", error);
		}
	};
	const [isOpen, setIsOpen] = useState(true);
	const [selectedAgeGroup, setSelectedAgeGroup] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const openModalOtobr = () => setIsModalOtobrOpen(true);
	const closeModalOtobr = () => setIsModalOtobrOpen(false);
	const [isModalOtobrOpen, setIsModalOtobrOpen] = useState(false);
	const [isGridView, setIsGridView] = useState(true);
	const [viewMode, setViewMode] = useState("grid");
	const toggleView = () => {
		setIsGridView(!isGridView);
	};
	const [sortType, setSortType] = useState(null);
	const [selectedCity, setSelectedCity] = useState("Москва");
	const [cityDropdownOpen, setCityDropdownOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalMenuOpen, setIsModalMenuOpen] = useState(false);
	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	const [priceRange, setPriceRange] = useState({ min: "", max: "" });
	const [selectedCondition, setSelectedCondition] = useState("Любое");
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedDiscount, setSelectedDiscount] = useState("");
	const cities = [
		"Москва",
		"Санкт-Петербург",
		"Новосибирск",
		"Екатеринбург",
		"Казань",
	];
	const [currentSlides, setCurrentSlides] = useState({});
	const getActiveFiltersCount = () => {
		let count = 0;
		if (selectedCategory && selectedCategory !== "Не выбрано") count++;
		if (priceRange.min) count++;
		if (selectedCondition && selectedCondition !== "Любое") count++;
		if (selectedAgeGroup && selectedAgeGroup !== "Взрослые (>18 лет)") count++;
		for (let key in activeFilters) {
			if (activeFilters[key]) count++;
		}
		return count;
	};
	const settings = (productId) => ({
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		afterChange: (current) => handleSlideChange(productId, current), // передаем id товара и текущий слайд
	});
	const handleSlideChange = (productId, current) => {
		setCurrentSlides((prevState) => ({
			...prevState,
			[productId]: current,
		}));
	};
	const ageGroups = [
		"Взрослые (>18 лет)",
		"Подростки (15-17 лет)",
		"Подростки (12-14 лет)",
		"Дети (12-14 лет)",
		"Дети (8-12 лет)",
		"Дети (5-7 лет)",
	];
	const conditions = ["Любое", "Новое", "Б/У"];
	const category = [
		"Форма для полевого игрока",
		"Форма для вратаря",
		"Тренировочный инвентарь",
		"Прочее",
	];
	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);
	const openModalMenu = () => setIsModalMenuOpen(true);
	const closeModalMenu = () => setIsModalMenuOpen(false);
	const openFilterModal = () => setIsFilterModalOpen(true);
	const closeFilterModal = () => setIsFilterModalOpen(false);
	const handleApplyFilter = () => {
		closeModal();
	};
	const handleClearSelection = () => {
		setSelectedAgeGroup("");
	};
	const handleCitySelect = (city) => {
		setSelectedCity(city);
		setCityDropdownOpen(false);
	};
	let filteredProducts = mainpg.filter(
		(product) =>
			product.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
			product.city === selectedCity &&
			(selectedAgeGroup === "" || product.ageGroup === selectedAgeGroup) &&
			(selectedCondition === "Любое" ||
				product.condition === selectedCondition) &&
			(selectedCategory.length === 0 ||
				selectedCategory.includes(product.category)) &&
			(!priceRange.min || product.price >= parseInt(priceRange.min)) &&
			(!priceRange.max || product.price <= parseInt(priceRange.max))
	);
	if (sortType === "price-asc") {
		filteredProducts.sort((a, b) => a.price - b.price);
	} else if (sortType === "price-desc") {
		filteredProducts.sort((a, b) => b.price - a.price);
	} else if (sortType === "rating") {
		filteredProducts.sort((a, b) => b.rating - a.rating);
	} else if (sortType === "date") {
		filteredProducts.sort((a, b) => b.dat - a.dat);
	}
	const groupedProducts = [];
	for (let i = 0; i < filteredProducts.length; i += 2) {
		groupedProducts.push(filteredProducts.slice(i, i + 2));
	}

	const handleApplySort = () => {
		closeSortModal();
	};
	const handleApplyAge = () => {
		closeAgeModal();
	};
	const [isPriceDropdownOpen, setIsPriceDropdownOpen] = useState(false);
	const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
	const [isConditionDropdownOpen, setIsConditionDropdownOpen] = useState(false);
	const togglePriceDropdown = () =>
		setIsPriceDropdownOpen(!isPriceDropdownOpen);
	const toggleCategoryDropdown = () =>
		setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
	const toggleConditionDropdown = () =>
		setIsConditionDropdownOpen(!isConditionDropdownOpen);
	const [activeFilters, setActiveFilters] = useState({
		discount: false,
		online: false,
	});
	const [isFilterActive, setIsFilterActive] = useState(false);
	const handleApplyFilterModal = () => {
		if (selectedCategory.length > 0 || priceRange.min || selectedCondition) {
			setIsFilterActive(true);
		} else {
			setIsFilterActive(false);
		}
		closeFilterModal();
	};
	const handleFilterClick = (filter) => {
		setActiveFilters((prevFilters) => ({
			...prevFilters,
			[filter]: !prevFilters[filter],
		}));

		if (filter === "adults") {
			setIsModalOpen(true);
		}
	};
	const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
	const [isConditionModalOpen, setIsConditionModalOpen] = useState(false);
	const openPriceModal = () => setIsPriceModalOpen(true);
	const closePriceModal = () => setIsPriceModalOpen(false);
	const openConditionModal = () => setIsConditionModalOpen(true);
	const closeConditionModal = () => setIsConditionModalOpen(false);
	const handleClearPriceSelection = () => setPriceRange({ min: "", max: "" });
	const handleClearConditionSelection = () => setSelectedCondition("Любое");
	const handleApplyPriceFilter = () => {
		// Применяем фильтр по цене
		closePriceModal();
	};
	const handleApplyConditionFilter = () => {
		// Применяем фильтр по состоянию
		closeConditionModal();
	};
	const handleCategoryClick = (category) => {
		setSelectedCategory((prevSelectedCategory) => {
			if (prevSelectedCategory.includes(category)) {
				// Убираем категорию, если она уже выбрана
				return prevSelectedCategory.filter((cat) => cat !== category);
			} else {
				// Добавляем категорию, если она еще не выбрана
				return [...prevSelectedCategory, category];
			}
		});
	};
	const handleResetFilters = () => {
		setSelectedCity("Выберите город");
		setSelectedAgeGroup("Выберите возрастную группу");
		setPriceRange({ min: 0, max: 45000 });
		setSelectedCategory([]);
		setSelectedCondition("Любое");
	};
	const clearSearch = () => {
		setSearchQuery("");
	};
	const [isSortModalOpen, setIsSortModalOpen] = useState(false);
	const openSortModal = () => setIsSortModalOpen(true);
	const closeSortModal = () => setIsSortModalOpen(false);

	const [isAgeModalOpen, setIsAgeModalOpen] = useState(false);
	const openAgeModal = () => setIsAgeModalOpen(true);
	const closeAgeModal = () => setIsAgeModalOpen(false);

	const handleClear = () => {
		setSelectedAgeGroup(null);
	};


	return (
		<div className="MobileList">
			<SearchBar
				searchQuery={searchQuery}
				setSearchQuery={setSearchQuery}
				clearSearch={clearSearch}
			/>
			<OneBtnModal isOpen={isModalOtobrOpen} onClose={closeModalOtobr} onClear={() => setViewMode("grid")}>
				<ViewModeSelector viewMode={viewMode} onClose={closeModalOtobr} children={<CategoryFilter category={category} selectedCategory={selectedCategory} handleCategoryClick={handleCategoryClick}/>} setViewMode={setViewMode} />
			</OneBtnModal>
			<OneBtnModal isOpen={isSortModalOpen} onClose={closeSortModal} onClear={() => setSortType("price-asc")} children={<SortModal isOpen={isSortModalOpen} sortType={sortType} onClose={closeSortModal} onSortChange={setSortType}  onApply={handleApplySort}/>}/>
			<Modal onClear={handleClear} hasSelection={!!selectedAgeGroup} isOpen={isAgeModalOpen} onClose={closeAgeModal} children={<Age ageGroups={ageGroups} isOpen={isAgeModalOpen} setSelectedAgeGroup={setSelectedAgeGroup}  selectedAgeGroup={selectedAgeGroup} onClose={closeAgeModal}  onClear={() => setSortType(null)} onApply={handleApplyAge}/>}/>
			<CitySelector
				selectedCity={selectedCity}
				cities={cities}
				handleCitySelect={handleCitySelect}
				cityDropdownOpen={cityDropdownOpen}
				setCityDropdownOpen={setCityDropdownOpen}
			/>
			<Filterss
				getActiveFiltersCount={getActiveFiltersCount}
				openFilterModal={openFilterModal}
				openModal={openModal}
				category={category}
				selectedCategory={selectedCategory}
				handleCategoryClick={handleCategoryClick}
				priceRange={priceRange}
				openPriceModal={openPriceModal}
				selectedCondition={selectedCondition}
				openConditionModal={openConditionModal}
				selectedAgeGroup={selectedAgeGroup}
				setSelectedAgeGroup={setSelectedAgeGroup}
				openAgeModal={openAgeModal}
			/>
			<ViewMode sortType={sortType} viewMode={viewMode} openSortModal={openSortModal} openModalOtobr={openModalOtobr}/>
			<TwoStepList viewMode={viewMode} groupedProducts={groupedProducts} settings={settings} currentSlides={currentSlides}/>
			<Recommendation/>
			<PriceFilterModal
				isPriceModalOpen={isPriceModalOpen}
				closePriceModal={closePriceModal}
				priceRange={priceRange}
				setPriceRange={setPriceRange}
				handleApplyPriceFilter={handleApplyPriceFilter}
			/>
			<ConditionModal
				isOpen={isConditionModalOpen}
				onClose={closeConditionModal}
				conditions={conditions}
				selectedCondition={selectedCondition}
				onSelectCondition={setSelectedCondition}
				onClear={handleClearConditionSelection}
				onApply={handleApplyConditionFilter}
			/>
			<FilterModal
				isOpen={isFilterModalOpen}
				onClose={closeFilterModal}
				selectedCity={selectedCity}
				onCitySelect={handleCitySelect}
				cities={cities}
				priceRange={priceRange}
				setPriceRange={setPriceRange}
				category={category}
				selectedCategory={selectedCategory}
				onCategoryClick={handleCategoryClick}
				selectedCondition={selectedCondition}
				onConditionSelect={setSelectedCondition}
				onApply={handleApplyFilterModal}
				onReset={handleResetFilters}
				closeFilterModal={closeFilterModal}
			/>
		</div>
	);
};





const ResponsiveComponent = () => {
	const [isPcVersion, setIsPcVersion] = useState(window.innerWidth > 1280);

	useEffect(() => {
		const handleResize = () => {
			setIsPcVersion(window.innerWidth > 1280);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return isPcVersion ? <PcVersion /> : <MobileListPage />;
};

export default ResponsiveComponent;


// function App() {
// 	const [push, setPush] = useState(["arena", "school"]);
// 	const navigate = useNavigate();
// 	const handleNotification = async () => {
// 		const categoriesString = push.join(",");
// 		const url = `/api/user/setcategoriesnew?categoriesnew=${categoriesString}`;
//
// 		try {
// 			const response = await fetch(url, {
// 				method: "GET", // или 'POST' в зависимости от требований
// 			});
//
// 			if (response.ok) {
// 				console.log("Успешно отправлено!");
// 			} else {
// 				console.error("Ошибка при отправке уведомлений");
// 			}
// 		} catch (error) {
// 			console.error("Ошибка:", error);
// 		}
// 	};
// 	const [searchQuery, setSearchQuery] = useState("");
// 	const openModalOtobr = () => setIsModalOtobrOpen(true);
// 	const closeModalOtobr = () => setIsModalOtobrOpen(false);
// 	const [isModalOtobrOpen, setIsModalOtobrOpen] = useState(false);
// 	const [isGridView, setIsGridView] = useState(true);
// 	const [viewMode, setViewMode] = useState("grid");
// 	const toggleView = () => {
// 		setIsGridView(!isGridView);
// 	};
// 	const [sortType, setSortType] = useState(null);
// 	const [selectedCity, setSelectedCity] = useState("Москва");
// 	const [cityDropdownOpen, setCityDropdownOpen] = useState(false);
// 	const [isModalOpen, setIsModalOpen] = useState(false);
// 	const [isModalMenuOpen, setIsModalMenuOpen] = useState(false);
// 	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
// 	const [selectedAgeGroup, setSelectedAgeGroup] =
// 		useState("Взрослые (>18 лет)");
// 	const [priceRange, setPriceRange] = useState({ min: "", max: "" });
// 	const [selectedCondition, setSelectedCondition] = useState("Любое");
// 	const [selectedCategory, setSelectedCategory] = useState("");
// 	const [selectedDiscount, setSelectedDiscount] = useState("");
// 	const cities = [
// 		"Москва",
// 		"Санкт-Петербург",
// 		"Новосибирск",
// 		"Екатеринбург",
// 		"Казань",
// 	];
// 	const [currentSlides, setCurrentSlides] = useState({});
// 	const getActiveFiltersCount = () => {
// 		let count = 0;
// 		if (selectedCategory && selectedCategory !== "Не выбрано") count++;
// 		if (priceRange.min) count++;
// 		if (selectedCondition && selectedCondition !== "Любое") count++;
// 		if (selectedAgeGroup && selectedAgeGroup !== "Взрослые (>18 лет)") count++;
// 		for (let key in activeFilters) {
// 			if (activeFilters[key]) count++;
// 		}
// 		return count;
// 	};
// 	const settings = (productId) => ({
// 		dots: true,
// 		infinite: true,
// 		speed: 500,
// 		slidesToShow: 1,
// 		slidesToScroll: 1,
// 		afterChange: (current) => handleSlideChange(productId, current), // передаем id товара и текущий слайд
// 	});
// 	const handleSlideChange = (productId, current) => {
// 		setCurrentSlides((prevState) => ({
// 			...prevState,
// 			[productId]: current,
// 		}));
// 	};
// 	const ageGroups = [
// 		"Взрослые (>18 лет)",
// 		"Подростки (15-17 лет)",
// 		"Подростки (12-14 лет)",
// 		"Дети (12-14 лет)",
// 		"Дети (8-12 лет)",
// 		"Дети (5-7 лет)",
// 	];
// 	const conditions = ["Любое", "Новое", "Б/У"];
// 	const category = [
// 		"Не выбрано",
// 		"Форма для полевого игрока",
// 		"Форма для вратаря",
// 		"Тренировочный инвентарь",
// 		"Прочее",
// 	];
// 	const openModal = () => setIsModalOpen(true);
// 	const closeModal = () => setIsModalOpen(false);
// 	const openModalMenu = () => setIsModalMenuOpen(true);
// 	const closeModalMenu = () => setIsModalMenuOpen(false);
// 	const openFilterModal = () => setIsFilterModalOpen(true);
// 	const closeFilterModal = () => setIsFilterModalOpen(false);
// 	const handleApplyFilter = () => {
// 		closeModal();
// 	};
// 	const handleClearSelection = () => {
// 		setSelectedAgeGroup("");
// 	};
// 	const handleCitySelect = (city) => {
// 		setSelectedCity(city);
// 		setCityDropdownOpen(false);
// 	};
// 	let filteredProducts = mainpg.filter(
// 		(product) =>
// 			product.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
// 			product.city === selectedCity &&
// 			(selectedAgeGroup === "" || product.ageGroup === selectedAgeGroup) &&
// 			(selectedCondition === "Любое" ||
// 				product.condition === selectedCondition) &&
// 			(selectedCategory.length === 0 ||
// 				selectedCategory.includes(product.category)) &&
// 			(!priceRange.min || product.price >= parseInt(priceRange.min)) &&
// 			(!priceRange.max || product.price <= parseInt(priceRange.max))
// 	);
// 	if (sortType === "price-asc") {
// 		filteredProducts.sort((a, b) => a.price - b.price);
// 	} else if (sortType === "price-desc") {
// 		filteredProducts.sort((a, b) => b.price - a.price);
// 	} else if (sortType === "rating") {
// 		filteredProducts.sort((a, b) => b.rating - a.rating);
// 	} else if (sortType === "date") {
// 		filteredProducts.sort((a, b) => b.dat - a.dat);
// 	}
// 	const groupedProducts = [];
// 	for (let i = 0; i < filteredProducts.length; i += 2) {
// 		groupedProducts.push(filteredProducts.slice(i, i + 2));
// 	}
// 	const [isSortModalOpen, setIsSortModalOpen] = useState(false);
// 	const openSortModal = () => setIsSortModalOpen(true);
// 	const closeSortModal = () => setIsSortModalOpen(false);
// 	const handleApplySort = () => {
// 		closeSortModal();
// 	};
// 	const [isPriceDropdownOpen, setIsPriceDropdownOpen] = useState(false);
// 	const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
// 	const [isConditionDropdownOpen, setIsConditionDropdownOpen] = useState(false);
// 	const togglePriceDropdown = () =>
// 		setIsPriceDropdownOpen(!isPriceDropdownOpen);
// 	const toggleCategoryDropdown = () =>
// 		setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
// 	const toggleConditionDropdown = () =>
// 		setIsConditionDropdownOpen(!isConditionDropdownOpen);
// 	const [activeFilters, setActiveFilters] = useState({
// 		discount: false,
// 		online: false,
// 	});
// 	const [isFilterActive, setIsFilterActive] = useState(false);
// 	const handleApplyFilterModal = () => {
// 		if (selectedCategory.length > 0 || priceRange.min || selectedCondition) {
// 			setIsFilterActive(true);
// 		} else {
// 			setIsFilterActive(false);
// 		}
// 		closeFilterModal();
// 	};
// 	const handleFilterClick = (filter) => {
// 		setActiveFilters((prevFilters) => ({
// 			...prevFilters,
// 			[filter]: !prevFilters[filter],
// 		}));
//
// 		if (filter === "adults") {
// 			setIsModalOpen(true);
// 		}
// 	};
// 	const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
// 	const [isConditionModalOpen, setIsConditionModalOpen] = useState(false);
// 	const openPriceModal = () => setIsPriceModalOpen(true);
// 	const closePriceModal = () => setIsPriceModalOpen(false);
// 	const openConditionModal = () => setIsConditionModalOpen(true);
// 	const closeConditionModal = () => setIsConditionModalOpen(false);
// 	const handleClearPriceSelection = () => setPriceRange({ min: "", max: "" });
// 	const handleClearConditionSelection = () => setSelectedCondition("Любое");
// 	const handleApplyPriceFilter = () => {
// 		// Применяем фильтр по цене
// 		closePriceModal();
// 	};
// 	const handleApplyConditionFilter = () => {
// 		// Применяем фильтр по состоянию
// 		closeConditionModal();
// 	};
// 	const handleCategoryClick = (category) => {
// 		setSelectedCategory((prevSelectedCategory) => {
// 			if (prevSelectedCategory.includes(category)) {
// 				// Убираем категорию, если она уже выбрана
// 				return prevSelectedCategory.filter((cat) => cat !== category);
// 			} else {
// 				// Добавляем категорию, если она еще не выбрана
// 				return [...prevSelectedCategory, category];
// 			}
// 		});
// 	};
// 	const handleResetFilters = () => {
// 		setSelectedCity("Выберите город");
// 		setSelectedAgeGroup("Выберите возрастную группу");
// 		setPriceRange({ min: 0, max: 45000 });
// 		setSelectedCategory([]);
// 		setSelectedCondition("Любое");
// 	};
// 	const clearSearch = () => {
// 		setSearchQuery("");
// 	};
//
// 	return (
// 		<>
// 			<Header
// 				sectionName={"Купить форму и инвентарь"}
// 				additionalClass={"section"}
// 				openModalMenu={openModalMenu}
// 			/>
// 			<main className="container">
//
// 				<div className="step step-2">
// 					<input
// 						type="text"
// 						placeholder="Поиск"
// 						className="search-input"
// 						value={searchQuery}
// 						onChange={(e) => setSearchQuery(e.target.value)}
// 					/>
// 					<span
// 						className={searchQuery ? "clear-button visible" : "clear-button"}
// 						onClick={clearSearch}>
// 						Отменить
// 					</span>
// 				</div>
// 				{isModalOtobrOpen && (
// 					<div className="modal">
// 						<div className="modal-menu">
// 							<div
// 								className="modal-menu-header slsl"
// 								style={{ height: "max-content" }}>
// 								<h2>Отображение объявлений</h2>
// 								<div
// 									className="modal-stroke"
// 									style={{
// 										display: "flex",
// 										flexDirection: "row",
// 										gap: "8px",
// 										justifyContent: "inherit",
// 									}}>
// 									<input
// 										type="radio"
// 										id="grid-view"
// 										name="viewMode"
// 										value="grid"
// 										checked={viewMode === "grid"}
// 										onChange={() => setViewMode("grid")}
// 									/>
// 									<label>
// 										Сеткой <p></p>
// 									</label>
// 								</div>
// 								<div
// 									className="modal-stroke"
// 									style={{
// 										display: "flex",
// 										flexDirection: "row",
// 										gap: "8px",
// 										justifyContent: "inherit",
// 									}}>
// 									<input
// 										type="radio"
// 										id="solo-view"
// 										name="soloMode"
// 										value="solo"
// 										checked={viewMode === "solo"}
// 										onChange={() => setViewMode("solo")}
// 									/>
// 									<label>
// 										По одному <p></p>
// 									</label>
// 								</div>
// 								<div
// 									className="modal-stroke"
// 									style={{
// 										display: "flex",
// 										flexDirection: "row",
// 										gap: "8px",
// 										justifyContent: "inherit",
// 									}}>
// 									<input
// 										type="radio"
// 										id="list-view"
// 										name="viewMode"
// 										value="list"
// 										checked={viewMode === "list"}
// 										onChange={() => setViewMode("list")}
// 									/>
// 									<label>
// 										Вертикально <p></p>
// 									</label>
// 								</div>
// 							</div>
//
// 							<button className="apply-btn" onClick={closeModalOtobr}>
// 								Отмена
// 							</button>
// 						</div>
// 					</div>
// 				)}
// 				{isModalMenuOpen && (
// 					<div className="modal">
// 						<div className="modal-menu">
// 							<div className="modal-menu-header">
// 								<div className="modal-stroke">
// 									Избранные <p>0</p>
// 								</div>
// 								<hr />
// 								<div className="modal-stroke">
// 									Размещенные <p>0</p>
// 								</div>
// 							</div>
//
// 							<button className="apply-btn" onClick={closeModalMenu}>
// 								Отмена
// 							</button>
// 						</div>
// 					</div>
// 				)}
//
// 				{/* Третья ступень: Выбранный город и кнопка выбора города */}
// 				<div className="step step-3">
// 					<div className="custom-select">
// 						<span className="city">
// 							<span></span>
// 							{selectedCity}
// 						</span>
// 						<button
// 							className="city-button"
// 							onClick={() => setCityDropdownOpen(!cityDropdownOpen)}>
// 							<span></span> Выбрать город
// 						</button>
// 						{cityDropdownOpen && (
// 							<div className="dropdown">
// 								{cities.map((city) => (
// 									<button key={city} onClick={() => handleCitySelect(city)}>
// 										{city}
// 									</button>
// 								))}
// 							</div>
// 						)}
// 					</div>
// 				</div>
//
// 				{/* Четвертая ступень: Активные фильтры со скроллом вправо */}
// 				<div className="step step-4">
// 					<div className="filters">
// 						<span
// 							className={`filter-button ${
// 								getActiveFiltersCount() > 0 ? "active" : ""
// 							}`}
// 							onClick={openFilterModal}>
// 							{getActiveFiltersCount() > 0 && (
// 								<span className="filter-count">{getActiveFiltersCount()}</span>
// 							)}
// 						</span>
//
// 						<div className="filter" onClick={openModal}>
// 							Возраст: {selectedAgeGroup ? selectedAgeGroup : "Не выбрано"}
// 						</div>
//
// 						{/* Отображаем возможные фильтры, исключая "Не выбрано" */}
// 						{category
// 							.filter((category) => category !== "Не выбрано")
// 							.map((category) => (
// 								<div
// 									key={category}
// 									className={`filter ${
// 										selectedCategory.includes(category) ? "active" : ""
// 									}`}
// 									onClick={() => handleCategoryClick(category)}>
// 									{category}
// 								</div>
// 							))}
//
// 						<div className="filter" onClick={openPriceModal}>
// 							Цена: {priceRange.min ? `от ${priceRange.min}` : "Не выбрано"}{" "}
// 							{priceRange.max ? `до ${priceRange.max}` : ""}
// 						</div>
//
// 						{/* Кнопка для открытия модального окна с выбором состояния */}
// 						<div className="filter" onClick={openConditionModal}>
// 							Состояние:{" "}
// 							{selectedCondition !== "Любое" ? selectedCondition : "Не выбрано"}
// 						</div>
// 					</div>
// 				</div>
//
// 				{isPriceModalOpen && (
// 					<div className="modal">
// 						<div className="modal-content">
// 							<div className="modal-header">
// 								<button
// 									className="back-button"
// 									onClick={closePriceModal}></button>
// 								<h2>Цена</h2>
// 								<button
// 									className="clear-btn"
// 									onClick={handleClearPriceSelection}>
// 									Очистить
// 								</button>
// 							</div>
// 							<div className="modal-body">
// 								<div className="price-range">
// 									<label htmlFor="min-price">Цена от:</label>
// 									<input
// 										type="number"
// 										id="min-price"
// 										value={priceRange.min}
// 										onChange={(e) =>
// 											setPriceRange({ ...priceRange, min: e.target.value })
// 										}
// 									/>
// 								</div>
// 								<div className="price-range">
// 									<label htmlFor="max-price">Цена до:</label>
// 									<input
// 										type="number"
// 										id="max-price"
// 										value={priceRange.max}
// 										onChange={(e) =>
// 											setPriceRange({ ...priceRange, max: e.target.value })
// 										}
// 									/>
// 								</div>
// 							</div>
// 							<div className="modal-footer">
// 								<button className="apply-btn" onClick={handleApplyPriceFilter}>
// 									Применить фильтр
// 								</button>
// 							</div>
// 						</div>
// 					</div>
// 				)}
//
// 				{isConditionModalOpen && (
// 					<div className="modal">
// 						<div className="modal-content">
// 							<div className="modal-header">
// 								<button
// 									className="back-button"
// 									onClick={closeConditionModal}></button>
// 								<h2>Состояние</h2>
// 								<button
// 									className="clear-btn"
// 									onClick={handleClearConditionSelection}>
// 									Очистить
// 								</button>
// 							</div>
// 							<div className="modal-body">
// 								{conditions.map((condition) => (
// 									<div key={condition} className="radio-group">
// 										<input
// 											type="radio"
// 											id={condition}
// 											name="condition"
// 											value={condition}
// 											checked={selectedCondition === condition}
// 											onChange={(e) => setSelectedCondition(e.target.value)}
// 										/>
// 										<label htmlFor={condition}>{condition}</label>
// 									</div>
// 								))}
// 							</div>
// 							<div className="modal-footer">
// 								<button
// 									className="apply-btn"
// 									onClick={handleApplyConditionFilter}>
// 									Применить фильтр
// 								</button>
// 							</div>
// 						</div>
// 					</div>
// 				)}
//
// 				{isFilterModalOpen && (
// 					<div className="modal modal-fullscreen">
// 						<div className="modal-content modal-content-filter">
// 							<div className="modal-header">
// 								<button
// 									className="back-button"
// 									onClick={closeFilterModal}></button>
// 								<h2 className="modal-title">Фильтры</h2>
//
// 								<a
// 									href=""
// 									onClick={handleResetFilters}
// 									className="clear-btn"
// 									style={{ textDecoration: "none" }}>
// 									Очистить
// 								</a>
// 							</div>
//
// 							<div className="modal-body">
// 								<label style={{ fontWeight: 700 }}>Выбрать город</label>
// 								<div className="filter-group">
// 									<div className="custom-select">
// 										<div className="dropdown-container">
// 											<div
// 												className="dropdown-trigger"
// 												onClick={() => setCityDropdownOpen(!cityDropdownOpen)}>
// 												<span className="dropdown-label">{selectedCity}</span>
// 												<span
// 													className="dropdown-value"
// 													style={{ rotate: "90deg" }}>
// 													&gt;
// 												</span>
// 											</div>
// 										</div>
// 										{cityDropdownOpen && (
// 											<div className="dropdown">
// 												{cities.map((city) => (
// 													<button
// 														key={city}
// 														onClick={() => handleCitySelect(city)}>
// 														{city}
// 													</button>
// 												))}
// 											</div>
// 										)}
// 									</div>
// 								</div>
//
// 								<div
// 									className="filter-group"
// 									style={{
// 										display: "flex",
// 										flexDirection: "column",
// 										gap: 16,
// 										marginTop: 8,
// 									}}>
// 									<label style={{ fontWeight: 700 }}>Стоимость</label>
// 									<div className="price-range">
// 										<input
// 											type="number"
// 											value={priceRange.min}
// 											onChange={(e) =>
// 												setPriceRange({ ...priceRange, min: e.target.value })
// 											}
// 											placeholder="От 0"
// 										/>
// 										<input
// 											type="number"
// 											value={priceRange.max}
// 											onChange={(e) =>
// 												setPriceRange({ ...priceRange, max: e.target.value })
// 											}
// 											placeholder="До 45000"
// 										/>
// 									</div>
// 									<div className="price-slider-container">
// 										<input
// 											type="range"
// 											min="0"
// 											max="22500"
// 											value={priceRange.min}
// 											onChange={(e) =>
// 												setPriceRange({
// 													...priceRange,
// 													min: Math.min(
// 														Number(e.target.value),
// 														priceRange.max - 1
// 													),
// 												})
// 											}
// 											className="price-slider"
// 										/>
// 										<input
// 											type="range"
// 											min="22500"
// 											max="45000"
// 											value={priceRange.max}
// 											onChange={(e) =>
// 												setPriceRange({
// 													...priceRange,
// 													max: Math.max(
// 														Number(e.target.value),
// 														priceRange.min + 1
// 													),
// 												})
// 											}
// 											className="price-slider"
// 										/>
// 									</div>
// 								</div>
//
// 								<div className="filter-group">
// 									<div className="radio-group">
// 										{category
// 											.filter((cat) => cat !== "Не выбрано")
// 											.map((cat) => (
// 												<div key={cat} className="switch-group">
// 													<label className="switch-label">
// 														<span style={{ fontWeight: 700 }}>{cat}</span>
// 														<label className="switch">
// 															<input
// 																type="checkbox"
// 																className="hidden"
// 																checked={selectedCategory.includes(cat)}
// 																onChange={() => handleCategoryClick(cat)}
// 															/>
// 															<span className="slider round"></span>
// 														</label>
// 													</label>
// 												</div>
// 											))}
// 									</div>
// 								</div>
//
// 								<div className="filter-group">
// 									<label style={{ fontWeight: 700 }}>Состояние</label>
// 									<div className="radio-group">
// 										<div className="form_radio">
// 											<input
// 												type="radio"
// 												id="new"
// 												name={"filed_" + "condition"}
// 												value="Новое"
// 												checked={selectedCondition === "Новое"}
// 												onChange={() => setSelectedCondition("Новое")}
// 											/>
// 											<label htmlFor="new">Новое</label>
// 										</div>
//
// 										<div>
// 											<input
// 												type="radio"
// 												id="used"
// 												name="condition"
// 												value="Б/У"
// 												checked={selectedCondition === "Б/У"}
// 												onChange={() => setSelectedCondition("Б/У")}
// 											/>
// 											<label htmlFor="used">Б/У</label>
// 										</div>
// 									</div>
// 								</div>
// 								<button
// 									className="apply-btn"
// 									onClick={handleApplyFilterModal}
// 									style={{ marginTop: 16 }}>
// 									Применить фильтр
// 								</button>
// 							</div>
// 						</div>
// 					</div>
// 				)}
//
// 				{/* Модальное окно для выбора возраста */}
// 				{isModalOpen && (
// 					<div className="modal">
// 						<div className="modal-content">
// 							<div className="modal-header">
// 								<button className="back-button" onClick={closeModal}></button>
// 								<h2>Возраст</h2>
// 								<button className="clear-btn" onClick={handleClearSelection}>
// 									Очистить
// 								</button>
// 							</div>
// 							<div className="modal-body">
// 								{ageGroups.map((group) => (
// 									<div key={group} className="radio-group">
// 										<input
// 											type="radio"
// 											id={group}
// 											name="ageGroup"
// 											value={group}
// 											checked={selectedAgeGroup === group}
// 											onChange={(e) => setSelectedAgeGroup(e.target.value)}
// 										/>
// 										<label htmlFor={group}>{group}</label>
// 									</div>
// 								))}
// 							</div>
// 							<div className="modal-footer">
// 								<button className="apply-btn" onClick={handleApplyFilter}>
// 									Применить фильтр
// 								</button>
// 							</div>
// 						</div>
// 					</div>
// 				)}
//
// 				{isSortModalOpen && (
// 					<div className="modal">
// 						<div className="modal-content">
// 							<div className="modal-header">
// 								<button
// 									className="back-button"
// 									onClick={closeSortModal}></button>
// 								<h2>Сортировка</h2>
// 								<button className="clear-btn" onClick={() => setSortType(null)}>
// 									Очистить
// 								</button>
// 							</div>
// 							<div className="modal-body">
// 								<div className="radio-group" style={{ flexDirection: "row" }}>
// 									<input
// 										type="radio"
// 										id="price-asc"
// 										name="sortType"
// 										value="price-asc"
// 										checked={sortType === "price-asc"}
// 										onChange={(e) => setSortType(e.target.value)}
// 									/>
// 									<label style={{ marginLeft: "0" }} htmlFor="price-asc">
// 										{" "}
// 										Cначала дешевле
// 									</label>
// 								</div>
// 								<div className="radio-group" style={{ flexDirection: "row" }}>
// 									<input
// 										type="radio"
// 										id="price-desc"
// 										name="sortType"
// 										value="price-desc"
// 										checked={sortType === "price-desc"}
// 										onChange={(e) => setSortType(e.target.value)}
// 									/>
// 									<label style={{ marginLeft: "0" }} htmlFor="price-desc">
// 										{" "}
// 										Cначала дороже
// 									</label>
// 								</div>
// 								<div className="radio-group" style={{ flexDirection: "row" }}>
// 									<input
// 										type="radio"
// 										id="date"
// 										name="sortType"
// 										value="date"
// 										checked={sortType === "date"}
// 										onChange={(e) => setSortType(e.target.value)}
// 									/>
// 									<label style={{ marginLeft: "0" }} htmlFor="price-desc">
// 										По дате
// 									</label>
// 								</div>
// 								<div className="radio-group" style={{ flexDirection: "row" }}>
// 									<input
// 										type="radio"
// 										id="rating"
// 										name="sortType"
// 										value="rating"
// 										checked={sortType === "rating"}
// 										onChange={(e) => setSortType(e.target.value)}
// 									/>
// 									<label style={{ marginLeft: "0" }} htmlFor="rating">
// 										По рейтингу
// 									</label>
// 								</div>
// 							</div>
// 							<div className="modal-footer">
// 								<button className="apply-btn" onClick={handleApplySort}>
// 									Применить сортировку
// 								</button>
// 							</div>
// 						</div>
// 					</div>
// 				)}
//
// 				{/* Пятая ступень: Кастомный селект для сортировки */}
// 				<div className="step step-5">
// 					<div className="custom-select">
// 						<button className="sort-button" onClick={openSortModal}>
// 							<span></span> Сортировка
// 						</button>
// 					</div>
// 					<button className="notify-button" onClick={openModalOtobr}>
// 						<span></span> Формат отображения
// 					</button>
// 				</div>
//
// 				{/* Список товаров с горизонтальным скроллом и двумя вертикальными рядами */}
// 				<div className="product-scroll-container">
// 					<div className="products-row">
// 						{groupedProducts.length > 0 ? (
// 							groupedProducts.map((group, index) => (
// 								<div className="product-column" key={index}>
// 									{group.map((product) => (
// 										<div
// 											className="product"
// 											key={product.id}
// 											onClick={() => {
// 												console.log(
// 													"Navigating to product:",
// 													product.id,
// 													"with products:",
// 													groupedProducts
// 												);
// 												navigate(`/equipment/${product.id}`, {
// 													state: { products: groupedProducts },
// 												});
// 											}}>
// 											<Slider {...settings(product.id)}>
// 												{product.images &&
// 													product.images.map((image, i) => (
// 														<div key={i} className="sadas2">
// 															<img src={image} alt={`Product Image ${i + 1}`} />
// 															<div className="slider-counter">
// 																{(currentSlides[product.id] || 0) + 1} из{" "}
// 																{product.images.length}
// 															</div>
// 														</div>
// 													))}
// 											</Slider>
//
// 											<div className="product-info">
// 												<h2>{product.name}</h2>
// 												<p>{product.location}</p>
// 												<p className="rating">
// 													<span>{product.rating}</span> (0)
// 												</p>
// 												<span className="product-izb"></span>
// 											</div>
// 										</div>
// 									))}
// 								</div>
// 							))
// 						) : (
// 							<div className="no-products">
// 								<p>По выбранным фильтрам объявлений нет</p>
// 							</div>
// 						)}
// 					</div>
// 				</div>
//
// 				<div className="step step-1">
// 					<h1>Химчистка</h1>
//
// 					<button className="back-button  rotate"></button>
// 				</div>
//
// 				<div
// 					className="product-scroll-container horizontal"
// 					style={{ padding: 0 }}>
// 					<div className="products-row horizontal-row">
// 						{xim.map((product) => (
// 							<div className="product-column" key={product.id}>
// 								<div className="product">
// 									<Slider {...settings}>
// 										{product.images &&
// 											product.images.map((image, i) => (
// 												<div key={i} className="sadas2">
// 													<img src={image} alt={`Product Image ${i + 1}`} />
// 													<div className="slider-counter">
// 														{(currentSlides[product.id] || 0) + 1} из{" "}
// 														{product.images.length}
// 													</div>
// 												</div>
// 											))}
// 									</Slider>
// 									<div className="product-info">
// 										<h2>{product.name}</h2>
// 										<p>
// 											от {product.price}р{" "}
// 											<span className="old-price">{product.oldPrice}р</span>
// 										</p>
// 										<p>{product.location}</p>
// 										<p className="rating">⭐ {product.rating} (0)</p>
// 										<span className="product-izb"></span>
// 									</div>
// 								</div>
// 							</div>
// 						))}
// 					</div>
// 				</div>
//
// 				<div className="step step-1">
// 					<h1>Ремонт формы и заточка</h1>
//
// 					<button className="back-button  rotate"></button>
// 				</div>
//
// 				<div className="product-scroll-container horizontal">
// 					<div className="products-row">
// 						{remont.map((product) => (
// 							<div className="product-column" key={product.id}>
// 								<div className="product">
// 									<Slider {...settings}>
// 										{product.images &&
// 											product.images.map((image, i) => (
// 												<div key={i} className="sadas2">
// 													<img src={image} alt={`Product Image ${i + 1}`} />
// 													<div className="slider-counter">
// 														{(currentSlides[product.id] || 0) + 1} из{" "}
// 														{product.images.length}
// 													</div>
// 												</div>
// 											))}
// 									</Slider>
// 									<div className="product-info">
// 										<h2>{product.name}</h2>
// 										<p>
// 											от {product.price}р{" "}
// 											<span className="old-price">{product.oldPrice}р</span>
// 										</p>
// 										<p>{product.location}</p>
// 										<p className="rating">⭐ {product.rating} (0)</p>
// 										<span className="product-izb"></span>
// 									</div>
// 								</div>
// 							</div>
// 						))}
// 					</div>
// 				</div>
//
// 				<div className="step step-1">
// 					<h1>Пошив формы</h1>
//
// 					<button className="back-button  rotate"></button>
// 				</div>
//
// 				<div className="product-scroll-container horizontal">
// 					<div className="products-row">
// 						{poshiv.map((product) => (
// 							<div className="product-column" key={product.id}>
// 								<div className="product">
// 									<Slider {...settings}>
// 										{product.images &&
// 											product.images.map((image, i) => (
// 												<div key={i} className="sadas2">
// 													<img src={image} alt={`Product Image ${i + 1}`} />
// 													<div className="slider-counter">
// 														{(currentSlides[product.id] || 0) + 1} из{" "}
// 														{product.images.length}
// 													</div>
// 												</div>
// 											))}
// 									</Slider>
// 									<div className="product-info">
// 										<h2>{product.name}</h2>
// 										<p>
// 											от {product.price}р{" "}
// 											<span className="old-price">{product.oldPrice}р</span>
// 										</p>
// 										<p>{product.location}</p>
// 										<p className="rating"> {product.rating} (0)</p>
// 										<span className="product-izb"></span>
// 									</div>
// 								</div>
// 							</div>
// 						))}
// 					</div>
// 				</div>
// 			</main>
// 			<Footer additionalClass={"section"} />
// 		</>
// 	);
// };

// export default App;
