import "./MainPage.css";
import { NavLink } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useEffect, useState } from "react";
import { createChat, getUserChats, getUsers } from "../../actions/chat";
import { getStoredUserId, getUserToken } from "../../actions/user";
import Preloader from "../ui/Preloader/Preloader";
import FunctionalButton from "../ui/FunctionalButton/FunctionalButton";
import FunctionalButtonLink from "../ui/FunctionalButton/FunctionalButtonLink";

export default function MainPage({currentUserId, currentUserToken, setCurrentUserId, setCurrentUserToken}) {
	return (
		<>
			<Header setCurrentUserId={setCurrentUserId} setCurrentUserToken={setCurrentUserToken} />
			<main>
				<div className="title">
					<h2>Вместо всего что ниже, здесь будет главная страница</h2>
				</div>
				<div className="links">
					<FunctionalButtonLink addClass={"main_page-button"} to={"/equipment"}>
						Перейти в разделы
					</FunctionalButtonLink>
				</div>
				{currentUserId && <ChatPicker currentUserToken={currentUserToken} currentUserId={currentUserId} />}
			</main>
			<Footer />
		</>
	);
}

const ChatPicker = ({currentUserToken, currentUserId}) => {
	const [users, setUsers] = useState(null);
	const [currentUserChats, setCurrentUserChats] = useState(null);
	const [selectedUser, setSelectedUser] = useState(0);
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [preloaderVisible, setPreloaderVisible] = useState(null);

	const createNewChat = () => {
		if (selectedUser) {
			const existingChat = currentUserChats.find(
				(chat) =>
					chat.userName ===
					`${JSON.parse(selectedUser).name} ${JSON.parse(selectedUser).surname}`
			);
			setIsError(false);
			setErrorMessage("");

			const errorTextTimeout = setTimeout(() => {
				setErrorMessage("");
				clearTimeout(errorTextTimeout)
			}, 5000)

			if (!existingChat) {
				setPreloaderVisible(true);
				createChat(JSON.parse(selectedUser).id).then(() => {
					setPreloaderVisible(false);
					setErrorMessage("Чат создан");
				});
			} else {
				setIsError(true);
				setErrorMessage("У вас уже есть чат с этим пользователем");
			}
		} else {
			setIsError(true);
			setErrorMessage("Выберите пользователя");
		}
	};

	useEffect(() => {
		if (currentUserToken) {
			getUsers()
				.then((response) => setUsers(response.data.result))
				.catch((err) => console.log(err));
			getUserChats(currentUserId)
				.then((response) => setCurrentUserChats(response.data.data))
				.catch((err) => console.log(err));
		}
	}, []);

	return (
		<>
			{users && (
				<div className="pick_user">
					<Preloader isVisible={preloaderVisible} />
					{users.length > 0 && (
						<div className="">
							<select
								name="user"
								id="userPick"
								value={selectedUser}
								onChange={(e) => setSelectedUser(e.target.value)}>
								<option value="0" disabled>
									Выберете пользователя для начала диалога
								</option>
								{users.map((user) => (
									<>
										{user.id !== currentUserId && (
											<option value={JSON.stringify(user)}>
												{user.name} {user.surname}
											</option>
										)}
									</>
								))}
							</select>
						</div>
					)}
					<FunctionalButton
						onClick={createNewChat}
						addClass={"main_page-button"}>
						Создать чат
					</FunctionalButton>
					<p className={isError ? "" : "success"}>{errorMessage}</p>
				</div>
			)}
		</>
	);
};
