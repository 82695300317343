import { useState } from "react";
import styles from "./DefaultInput.module.css";

export default function DefaultInput({
	placeholder,
	label,
	type,
	id,
	name,
	value,
	min,
	max,
	maxLength,
	pattern,
	isRequired,
	isCorrect,
	onChange,
	onFocus,
	onBlur,
	message,
	hint,
	addClass,
}) {
	const [showPassword, setShowPassword] = useState(false);

	const togglePassword = (state) => {
		setShowPassword(!state);
	};

	const inputFieldClass = `${styles.defaultInput__input__field} ${
		isCorrect !== undefined && !isCorrect ? styles.incorrect : ""
	}`;

	return (
		<label
			htmlFor={id}
			className={`${styles.defaultInput__wrapper} ${
				addClass ? " " + addClass : ""
			}`}>
			<div className={styles.defaultInput__input}>
				{label && (
					<p className={styles.defaultInput__input__label}>
						{label}
						{isRequired && <span></span>}
					</p>
				)}
				<div className={inputFieldClass}>
					{hint && (
						<span
							className={styles.defaultInput__input__field__hint}
							dangerouslySetInnerHTML={{ __html: hint }}></span>
					)}
					<input
						id={id}
						value={value ? value : ""}
						name={name}
						type={type === "password" && showPassword ? "text" : type}
						pattern={pattern && pattern}
						min={min && min}
						max={max && max}
						maxLength={maxLength && maxLength}
						placeholder={placeholder}
						onFocus={onFocus}
						onBlur={onBlur}
						onChange={onChange}
						required={isRequired && isRequired}
					/>
				</div>
				{type === "password" && (
					<div
						className={styles.defaultInput__input__showPassword}
						onClick={() => togglePassword(showPassword)}>
						<img
							src={
								showPassword
									? "/images/buttons/eye-slash.svg"
									: "/images/buttons/eye.svg"
							}
							alt="eye"
						/>
					</div>
				)}
			</div>
			{message !== "" && (
				<p className={styles.defaultInput__input__message}>{message}</p>
			)}
		</label>
	);
}
