import { useEffect, useState } from "react";
import styles from "./ImageLessAvatar.module.css";

export default function ImageLessAvatar({ name, surname, addClass }) {
	const [backgroundGradient, setBackgroundGradient] = useState("none");
	const [avatarLetters, setAvatarLetters] = useState("");

	useEffect(() => {
		function createUserAvatar() {
			const letters = [
				..."абвгдеёжзийклмнопрстуфхцчшщъыьэюя",
				..."abcdefghijklmnopqrstuvwxyz",
			];

			const gradientCodes = [
				"linear-gradient(to right, #ff7e5f, #feb47b)",
				"linear-gradient(to right, #6a11cb, #2575fc)",
				"linear-gradient(to right, #ff512f, #dd2476)",
				"linear-gradient(to right, #00c6ff, #0072ff)",
				"linear-gradient(to right, #ff6a00, #ee0979)",
				"linear-gradient(to right, #3a6073, #3a7bd5)",
				"linear-gradient(to right, #f8cdda, #1d2b64)",
				"linear-gradient(to right, #ff758c, #ff7eb3)",
				"linear-gradient(to right, #43e97b, #38f9d7)",
				"linear-gradient(to right, #ff9a9e, #fad0c4)",
				"linear-gradient(to right, #00d2ff, #9281ff)",
				"linear-gradient(to right, #6a11cb, #ff64b8)",
				"linear-gradient(to right, #f2c94c, #f2a900)",
				"linear-gradient(to right, #d9a7c7, #fffcdc)",
				"linear-gradient(to right, #ffafbd, #ffc3a0)",
				"linear-gradient(to right, #5ee0c3, #06aaf0)",
				"linear-gradient(to right, #00c6ff, #f0e130)",
				"linear-gradient(to right, #5f2c91, #49a09d)",
				"linear-gradient(to right, #ff4b1f, #1fddff)",
				"linear-gradient(to right, #00d2ff, #00c6ff)",
				"linear-gradient(to right, #f8cdda, #1d2b64)",
				"linear-gradient(to right, #c2e9fb, #a1c4fd)",
				"linear-gradient(to right, #c2c2fe, #c2e9fb)",
				"linear-gradient(to right, #c2e9fb, #a1c4fd)",
				"linear-gradient(to right, #f5f7fa, #c3cfe2)",
				"linear-gradient(to right, #a18cd1, #fbc2eb)",
				"linear-gradient(to right, #ff9a9e, #fad0c4)",
				"linear-gradient(to right, #f6d365, #fda085)",
				"linear-gradient(to right, #ffd89b, #19547b)",
				"linear-gradient(to right, #ff758c, #ff7eb3)",
				"linear-gradient(to right, #84fab0, #8fd3f4)",
				"linear-gradient(to right, #ce9ffc, #7366ff)",
				"linear-gradient(to right, #f1c40f, #f39c12)",
				"linear-gradient(to right, #348f50, #56b4d3)",
				"linear-gradient(to right, #ff6a6a, #ffcbcb)",
				"linear-gradient(to right, #00b09b, #96c93d)",
				"linear-gradient(to right, #4e54c8, #8f94fb)",
				"linear-gradient(to right, #fbc2eb, #e6dee9)",
				"linear-gradient(to right, #ff49ac, #f4335c)",
				"linear-gradient(to right, #12c2e9, #c471ed)",
				"linear-gradient(to right, #00f2ea, #3f8f5f)",
				"linear-gradient(to right, #333399, #ffcc00)",
				"linear-gradient(to right, #abecd6, #fbed96)",
				"linear-gradient(to right, #c2c2e4, #5594f2)",
				"linear-gradient(to right, #36d1dc, #5b86e5)",
				"linear-gradient(to right, #2980b9, #6dd5ed)",
				"linear-gradient(to right, #fc00ff, #00dbde)",
				"linear-gradient(to right, #1c92d2, #f2fcfe)",
				"linear-gradient(to right, #ff0844, #ffb199)",
				"linear-gradient(to right, #ff5858, #f09819)",
				"linear-gradient(to right, #30cfd0, #330867)",
				"linear-gradient(to right, #f1c40f, #e67e22)",
				"linear-gradient(to right, #2E86C1, #E74C3C)",
				"linear-gradient(to right, #FFB6F3, #1F4037)",
				"linear-gradient(to right, #F6D365, #FDA085)",
				"linear-gradient(to right, #1D2B64, #F8Cdda)",
				"linear-gradient(to right, #DCE35B, #45A247)",
				"linear-gradient(to right, #ff6a00, #ee0979)",
				"linear-gradient(to right, #00c6ff, #f0e130)",
			];

			const letterBackgrounds = letters.reduce((acc, key, index) => {
				acc[key] = gradientCodes[index];
				return acc;
			}, {});

			if (name.length > 0) {
				setBackgroundGradient(
					letterBackgrounds[name[0].toString().toLowerCase()]
				);
				setAvatarLetters(`${name[0].toUpperCase()}${surname[0].toUpperCase()}`);
			}
		}
		createUserAvatar();
	}, [name, surname]);

	return (
		<div
			className={`${styles.imageLessAvatar__wrapper} ${addClass ? addClass : ""}`}
			style={{background: backgroundGradient}}>
			<span className={styles.imageLessAvatar__wrapper_letters}>{avatarLetters}</span>
		</div>
	);
}
