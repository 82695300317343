import { NavLink } from "react-router-dom";
import styles from "./FunctionalButton.module.css";

export default function FunctionalButtonLink({ children, onClick, addClass, to }) {
	const handleClick = () => {
		if (onClick) onClick();
	};
	return (
		<NavLink to={to}>
			<button
				className={`${styles.functionalButton} ${addClass ? addClass : ""}`}
				onClick={handleClick}>
				{children}
			</button>
		</NavLink>
	);
}
