"use client";

import "./Header.css";
import AuthorizationPopup from "../Authorization/AuthorizationPopup";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { currentScreenWidth, mobileScreenBreakpoint } from "../../utils";

export default function Header({
	additionalClass,
	sectionName,
	openModalMenu,
	setCurrentUserId,
	setCurrentUserToken
}) {
	const [authOpen, setAuthOpen] = useState(false);

	const toggleAuthPopup = (state) => {
		setAuthOpen(state);
	};

	const location = useLocation();

	const getBack = () => {
		if (window.history.length > 1) {
			window.history.back();
		} else {
			window.history.location("/");
		}
	};

	return (
		<>
			{location.pathname !== "/" &&
			currentScreenWidth <= mobileScreenBreakpoint ? (
				<header className="header__mobile-content">
					<span
					className="back-button"
					onClick={() => getBack()}></span>
					<h1>{sectionName}</h1>
				</header>
			) : (
				<header className={`header ${additionalClass ? additionalClass : ""}`}>
					<img src="logo.png" alt="logo" />
					<div className="header__main-buttons">
						<button className="main">
							<NavLink to="/">Главное</NavLink>
						</button>
						<button className="services">Сервисы</button>
					</div>
					<button className="header__add-announcement-button">
						Добавить объявление +
					</button>
					<nav className="header__nav-buttons">
						<button>
							<NavLink to="/chat">
								<img src="/images/header/chat.svg" alt="profile" />
								<span>Входящие</span>
							</NavLink>
						</button>
						<button>
							<img src="/images/header/journal.svg" alt="profile" />
							<span>Журнал</span>
						</button>
						<button>
							<img src="/images/header/desired.svg" alt="profile" />
							<span>Желаемое</span>
						</button>
						<button>
							<img src="/images/header/cart.svg" alt="profile" />
							<span>Корзина</span>
						</button>
						<button>
							<img src="/images/header/notifications.svg" alt="profile" />
							<span>Уведомления</span>
						</button>
						<button onClick={() => toggleAuthPopup(true)}>
							<img src="/images/header/login_logout.svg" alt="profile" />
							<span>Войти</span>
						</button>
					</nav>
					<nav className="header__nav-buttons mobile">
						<NavLink
							to="/chat"
							style={{ display: "flex", alignItems: "center" }}>
							<img
								src="/images/header/chat.svg"
								alt="Чат"
								style={{ width: "44px" }}
							/>
						</NavLink>
						<img src="/images/header/cart_mobile.svg" alt="Корзина" />
						<img src="/images/header/desired_mobile.svg" alt="Избранное" />
						<img
							src="/images/header/login_logout_mobile.svg"
							alt="Войти"
							onClick={() => toggleAuthPopup(true)}
						/>
					</nav>
					<AuthorizationPopup isOpen={authOpen} toggle={toggleAuthPopup} setCurrentUserId={setCurrentUserId} setCurrentUserToken={setCurrentUserToken} />
				</header>
			)}
		</>
	);
}
