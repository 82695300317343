export const currentScreenWidth = window.innerWidth;
export const mobileScreenBreakpoint = 570;

export function formatDate(
	dateString,
	handleFormattedDate,
	timeOnly,
	dateOnly
) {
	const now = new Date();
	const date = new Date(dateString);

	const diffTime = now - date;
	const diffDays = diffTime / (1000 * 60 * 60 * 24);

	const options = {
		hour: "2-digit",
		minute: "2-digit",
		hour12: false,
	};

	const formatDateParts = (date) => {
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}.${month}.${year}`;
	};

	if (timeOnly) {
		if (handleFormattedDate)
			handleFormattedDate(`${date.toLocaleTimeString("en-GB", options)}`);
		return `${date.toLocaleTimeString("en-GB", options)}`;
	} else if (dateOnly) {
		if (diffDays < 1) {
			if (handleFormattedDate) handleFormattedDate(`Сегодня`);
			return `Сегодня`;
		} else if (diffDays < 2) {
			if (handleFormattedDate) handleFormattedDate(`Вчера`);
			return `Вчера`;
		} else {
			if (handleFormattedDate) handleFormattedDate(`${formatDateParts(date)}`);
			return `${formatDateParts(date)}`;
		}
	} else {
		if (diffDays < 1) {
			if (handleFormattedDate)
				handleFormattedDate(
					`Сегодня, ${date.toLocaleTimeString("en-GB", options)}`
				);
			return `Сегодня, ${date.toLocaleTimeString("en-GB", options)}`;
		} else if (diffDays < 2) {
			if (handleFormattedDate)
				handleFormattedDate(
					`Вчера, ${date.toLocaleTimeString("en-GB", options)}`
				);
			return `Вчера, ${date.toLocaleTimeString("en-GB", options)}`;
		} else {
			if (handleFormattedDate)
				handleFormattedDate(
					`${formatDateParts(date)}, ${date.toLocaleTimeString(
						"en-GB",
						options
					)}`
				);
			return `${formatDateParts(date)}, ${date.toLocaleTimeString(
				"en-GB",
				options
			)}`;
		}
	}
}
