import "./Chat.css";
import SearchInput from "../ui/SearchInput/SearchInput";
import React, { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import Header from "../Header/Header";
import {
	currentScreenWidth,
	formatDate,
	mobileScreenBreakpoint,
} from "../../utils";
import {
	checkNewMessage,
	connectToChatHub,
	deleteMessage,
	getAllSpecificChatMessages,
	getChatMessagesAfterSpecificOne,
	getChatMessagesBeforeSpecificOne,
	getCountBetweenMessages,
	getOneSpecificChatMessage,
	// getChatMessagesAfterSpecificOne,
	// getChatMessagesBeforeSpecificOne,
	// getOneSpecificChatMessage,
	getSpecificChatMessages,
	getSpecificChatPinnedMessages,
	getUserChats,
	pinMessage,
	sendFileMessage,
	sendMessage,
	sendReplyMessage,
	unpinMessage,
} from "../../actions/chat";
import { getStoredUserId, getUserToken } from "../../actions/user";
import ImageLessAvatar from "../ui/ImageLessAvatar/ImageLessAvatar";
import { HubConnectionBuilder } from "@microsoft/signalr";
import Preloader from "../ui/Preloader/Preloader";

export default function Chat({ currentUserId, currentUserToken }) {
	if (!currentUserToken) {
		window.location.href = "/";
	}

	const [connection, setConnection] = useState(null);
	const [chatTab, setChatTab] = useState("messages");
	const [currentChat, setCurrentChat] = useState(null);
	const [mobileChatWindowOpen, setMobileChatWindowOpen] = useState(false);

	const setCurrentOpenedChat = (chat) => {
		setCurrentChat(chat);
		setMobileChatWindowOpen(true);
	};

	useEffect(() => {
		const newConnection = new HubConnectionBuilder()
			.withUrl(`${process.env.REACT_APP_PUBLIC_API_URL}/chat`, {
				accessTokenFactory: () => currentUserToken,
				withCredentials: false,
			})
			.build();

		newConnection
			.start()
			.then(() => {
				console.log("Connected to SignalR!");
			})
			.catch((err) =>
				console.log("Error while establishing connection: ", err)
			);

		setConnection(newConnection);

		// Cleanup on component unmount
		return () => {
			if (connection) {
				connection.stop().then(() => console.log("Disconnected from SignalR"));
			}
		};
	}, []);

	return (
		<>
			<Header sectionName={"Входящие"} />
			<main
				className="chat__wrapper"
				style={{
					width:
						currentScreenWidth > mobileScreenBreakpoint
							? "initial"
							: currentScreenWidth + "px",
				}}>
				<ChatHeader chatTab={chatTab} setChatTab={setChatTab} />
				<div className="chat__container">
					<ChatList
						connection={connection}
						currentUserId={currentUserId}
						chatTab={chatTab}
						setCurrentOpenedChat={setCurrentOpenedChat}
						mobileChatWindowOpen={mobileChatWindowOpen}
						setMobileChatWindowOpen={setMobileChatWindowOpen}
					/>
					{currentScreenWidth > mobileScreenBreakpoint && (
						<ChatWindow
							connection={connection}
							currentUserId={currentUserId}
							currentChat={currentChat}
							setCurrentChat={setCurrentChat}
							mobileChatWindowOpen={mobileChatWindowOpen}
							setMobileChatWindowOpen={setMobileChatWindowOpen}
						/>
					)}
				</div>
			</main>
			{currentScreenWidth <= mobileScreenBreakpoint && (
				<ChatWindow
					connection={connection}
					currentUserId={currentUserId}
					currentChat={currentChat}
					setCurrentChat={setCurrentChat}
					mobileChatWindowOpen={mobileChatWindowOpen}
					setMobileChatWindowOpen={setMobileChatWindowOpen}
				/>
			)}
		</>
	);
}

const ChatHeader = ({ chatTab, setChatTab }) => {
	return (
		<div className="chat__header">
			<h1>Входящие</h1>
			<div className="chat__header-tabs">
				<span
					className={`chat__tab ${chatTab === "messages" && "active"}`}
					onClick={() => setChatTab("messages")}>
					Сообщения
				</span>
				<span
					className={`chat__tab ${chatTab === "notifications" && "active"}`}
					onClick={() => setChatTab("notifications")}>
					Уведомления
				</span>
			</div>
		</div>
	);
};

const ChatList = ({
	connection,
	currentUserId,
	chatTab,
	currentChat,
	setCurrentOpenedChat,
}) => {
	const [preloaderVisible, setPreloaderVisible] = useState(false);
	const [openChatMenuId, setOpenChatMenuId] = useState(null);
	const [userChats, setUserChats] = useState(null);

	const handleOpenChatMenu = (id, event) => {
		event.stopPropagation();
		setOpenChatMenuId(openChatMenuId === id ? null : id);
	};

	useEffect(() => {
		setPreloaderVisible(true);
		getUserChats()
			.then((response) => {
				if (response && response.status === 200) {
					setUserChats(response.data.data);
				}
			})
			.then(() => {
				setTimeout(() => {
					setPreloaderVisible(false);
				}, 150);
			})
			.catch((error) => {
				throw new Error(error);
			});
	}, []);

	return (
		<div className="chat__list-container">
			<Preloader isVisible={preloaderVisible} />
			<div className="chat__list-header">
				<SearchInput placeholder={"Поиск"} />
				<div className="chat__list-archive-info">
					<div>
						<img src="images/icons/chat_archive.svg" alt="В Архиве" />
						<span>В архиве</span>
					</div>
					<span className="chat__list-archive-indicator">1</span>
				</div>
			</div>
			<div className="chat__list-previews">
				{userChats && userChats.length > 0 ? (
					userChats.map((chat) => (
						<ChatPreview
							connection={connection}
							currentUserId={currentUserId}
							key={chat.id}
							chat={chat}
							openChatMenuId={openChatMenuId}
							handleOpenChatMenu={handleOpenChatMenu}
							currentChat={currentChat}
							setCurrentOpenedChat={setCurrentOpenedChat}
						/>
					))
				) : (
					<div className="chat__no-chats">
						<img src="images/chat/no-chats.svg" alt="Выберите диалог" />
						<p>Чатов пока нет</p>
					</div>
				)}
			</div>
		</div>
	);
};

const ChatPreview = ({
	connection,
	currentUserId,
	chat,
	currentChat,
	setCurrentOpenedChat,
	handleOpenChatMenu,
	openChatMenuId,
}) => {
	const [swipeMenuStatus, setSwipeMenuStatus] = useState(null);
	const [uncheckedAmount, setUncheckedAmount] = useState(chat.countNotCheck);
	const [lastChatMessageText, setLastChatMessageText] = useState(
		chat.lastMessage
	);
	const [lastChatMessageSenderId, setLastChatSenderMessageId] = useState(
		chat.userMessageid
	);
	const [lastChatMessageChecked, setLastChatMessageChecked] = useState(
		chat.checkedMessage
	);
	const [lastChatMessageDate, setLastChatMessageDate] = useState(
		formatDate(chat.createDateTime)
	);
	const [showUnchecked, setShowUnchecked] = useState(false);

	const openSwipeMenu = (status) => {
		if (currentScreenWidth <= mobileScreenBreakpoint) {
			if (swipeMenuStatus === null) {
				setSwipeMenuStatus(status);
			} else {
				setSwipeMenuStatus(null);
			}
		}
	};

	const handleOpenChatWindow = (chat) => {
		if (swipeMenuStatus) {
			setSwipeMenuStatus(null);
		} else {
			setCurrentOpenedChat(chat);
		}
	};

	const swipeHandlers = useSwipeable({
		onSwipedLeft: () => openSwipeMenu("right"),
		onSwipedRight: () => openSwipeMenu("left"),
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
	});

	useEffect(() => {
		if (connection && chat) {
			const handlePreviewCheck = async (id, checkerId) => {
				if (checkerId === currentUserId) {
					setUncheckedAmount((prev) => {
						if (prev >= 0) {
							return prev - 1;
						}
					});
				} else {
					const checkedMessage = await getOneSpecificChatMessage(id);
					if (checkedMessage.data.dialogueId === chat.id) {
						setLastChatMessageChecked(true);
					}
				}
			};

			const handleReceiveMessage = (newMessage, senderId) => {
				if (chat.id === newMessage.dialogueId) {
					setLastChatMessageText(newMessage.message);
					setLastChatSenderMessageId(senderId);
					setLastChatMessageChecked(false);
					setLastChatMessageDate(formatDate(newMessage.craeteDateTime));

					if (senderId !== currentUserId) {
						setUncheckedAmount((prev) => {
							return prev + 1;
						});
					}
				}
			};

			const handleDeleteChatMessage = (messageData) => {
				if (messageData.idMessageLast.dialogueId === chat.id) {
					setLastChatMessageText(messageData.idMessageLast.message);
					setLastChatMessageDate(
						formatDate(messageData.idMessageLast.craeteDateTime)
					);
					setLastChatSenderMessageId(messageData.idMessageLast.userid);
					setLastChatMessageChecked(messageData.idMessageLast.checked);
				}
			};

			connection.on("Check", handlePreviewCheck);
			connection.on("Delete", handleDeleteChatMessage);
			connection.on("Add", handleReceiveMessage);

			return () => {
				connection.off("Check", handlePreviewCheck);
				connection.off("Delete", handleDeleteChatMessage);
				connection.off("Add", handleReceiveMessage);
			};
		}
	}, [connection, chat, uncheckedAmount]);

	return (
		<div
			className={`chat__preview ${
				!currentChat && swipeMenuStatus === "right"
					? "swipe-menu-right"
					: !currentChat && swipeMenuStatus === "left"
					? "swipe-menu-left"
					: ""
			}`}
			{...swipeHandlers}>
			<div className="chat__preview-swipe-menu left">
				<div className="chat__preview-menu-item read-all">
					<img src="/images/chat/read-all.svg" alt="Прочитать" />
					<p>Прочитать</p>
				</div>
				<div className="chat__preview-menu-item pin">
					<img src="/images/chat/pin-chat.svg" alt="Закрепить" />
					<p>Закрепить</p>
				</div>
				<div className="chat__preview-menu-item set-important">
					<img src="/images/chat/star.svg" alt="Важное" />
					<p>Важное</p>
				</div>
			</div>

			<div
				className="chat__preview-content"
				onClick={() => handleOpenChatWindow(chat)}>
				{chat.avatar ? (
					<img
						className="chat__preview-avatar"
						src="images/placeholder.png"
						alt="Аватарка"
					/>
				) : (
					<ImageLessAvatar
						name={chat.userName.split(" ")[0]}
						surname={chat.userName.split(" ")[1]}
						addClass="chat__preview-avatar"
					/>
				)}
				<div className="chat__preview-main">
					<div className="chat__preview-header">
						<h4>{chat.userName}</h4>
						<div className="chat__preview-header-info">
							<span>
								{lastChatMessageDate !== formatDate("0001-01-01T00:00:00")
									? lastChatMessageDate
									: ""}
							</span>
							<span
								className="chat__preview-header-menu-button"
								onClick={(e) => handleOpenChatMenu(chat.id, e)}>
								<img src="/images/chat/tri-menu.svg" alt="Меню сообщения" />
							</span>
						</div>
					</div>
					<div className="chat_preview-message-text">
						<p>
							{lastChatMessageText ? lastChatMessageText : "Сообщений ещё нет."}
						</p>
						{lastChatMessageSenderId === currentUserId && (
							<>
								{lastChatMessageChecked ? (
									<img
										className="chat_preview-message-status"
										src="/images/chat/status-read.svg"
										alt="Статус сообщения"
									/>
								) : (
									<img
										className="chat_preview-message-status"
										src="/images/chat/status-sent.svg"
										alt="Статус сообщения"
									/>
								)}
							</>
						)}
					</div>
					<div className="chat_preview-message-extras">
						{uncheckedAmount > 0 && <span>{uncheckedAmount}</span>}
						{/* {chat.pinned && <img src="/images/chat/pin.svg" alt="Закреплено" />} */}
						{/* {chat.favored && <img src="/images/chat/star.svg" alt="Важное" />} */}
					</div>
				</div>
			</div>

			<div className="chat__preview-swipe-menu right">
				<div className="chat__preview-menu-item delete">
					<img src="/images/icons/delete.svg" alt="Удалить" />
					<p>Удалить</p>
				</div>
				<div className="chat__preview-menu-item archive">
					<img src="/images/icons/chat_archive.svg" alt="В архив" />
					<p>В архив</p>
				</div>
			</div>

			<div
				className={`chat__preview-menu ${
					openChatMenuId === chat.id ? "opened" : ""
				}`}>
				<div
					className="chat__preview-menu-item read-all"
					onMouseEnter={() => setShowUnchecked(true)}
					onMouseLeave={() => setShowUnchecked(false)}>
					{uncheckedAmount > 0 && (
						<span
							className={`message_amount ${showUnchecked ? "visible" : ""}`}>
							{uncheckedAmount}
						</span>
					)}
					<img src="/images/chat/read-all.svg" alt="Прочитать" />
					<p>Прочитать</p>
				</div>
				<div className="chat__preview-menu-item pin">
					<img src="/images/chat/pin-chat.svg" alt="Закрепить" />
					<p>Закрепить</p>
				</div>
				<div className="chat__preview-menu-item set-important">
					<img src="/images/chat/star.svg" alt="Важное" />
					<p>Важное</p>
				</div>
				<div className="chat__preview-menu-item delete">
					<img src="/images/icons/delete.svg" alt="Удалить" />
					<p>Удалить</p>
				</div>
				<div className="chat__preview-menu-item archive">
					<img src="/images/icons/chat_archive.svg" alt="В архив" />
					<p>В архив</p>
				</div>
				<div
					className="chat__preview-menu-item close"
					onClick={(e) => handleOpenChatMenu(null, e)}>
					<img src="/images/icons/cross-white.svg" alt="Закрыть" />
				</div>
			</div>
		</div>
	);
};

const ChatWindow = React.memo(
	({
		connection,
		currentUserId,
		currentChat,
		setCurrentChat,
		mobileChatWindowOpen,
		setMobileChatWindowOpen,
	}) => {
		const [preloaderVisible, setPreloaderVisible] = useState(true);

		const [openMessageMenuId, setOpenMessageMenuId] = useState(null);

		const [currentChatMessages, setCurrentChatMessages] = useState(null);
		const [groupedMessages, setGroupedMessages] = useState(null);

		const [pinnedMessages, setPinnedMessages] = useState(null);

		const [unpinMessageModalOpen, setUnpinMessageModalOpen] = useState(false);
		const [deleteMessageModalOpen, setDeleteMessageModalOpen] = useState(false);

		const [newMessageText, setNewMessageText] = useState("");
		const [newMessageImage, setNewMessageImage] = useState(null);
		const [newMessageImageUrl, setNewMessageImageUrl] = useState(null);
		const [newMessageReplyTarget, setNewMessageReplyTarget] = useState(null);
		const [messageToDeleteId, setMessageToDeleteId] = useState(null);

		const [canSend, setCanSend] = useState(true);
		const [highlightedPanelVisible, setHighlightedPanelVisible] = useState();

		const [isScrolling, setIsScrolling] = useState(false);

		const messageDateContainerRef = useRef(null);
		const messagesContainerRef = useRef(null);

		const handleOpenMessageMenu = (id, event) => {
			event.stopPropagation();
			setOpenMessageMenuId(openMessageMenuId === id ? null : id);
		};

		const swipeBack = () => {
			setMobileChatWindowOpen(false);
			setTimeout(() => {
				setCurrentChat(null);
				setCurrentChatMessages(null);
				setGroupedMessages(null);
				resetMessageParams();
			}, 250);
		};

		const swipeHandlers = useSwipeable({
			onSwipedRight: () => swipeBack(),
			preventDefaultTouchmoveEvent: true,
			trackMouse: true,
		});

		const attachFile = (event) => {
			const file = event.target.files[0];

			if (file) {
				setNewMessageImage(file);
				setHighlightedPanelVisible(true);
				setNewMessageImageUrl(URL.createObjectURL(file));

				console.log(URL.createObjectURL(file));
			} else {
				console.log("no file selected");
			}
		};

		const closeHighlightedPanel = () => {
			setHighlightedPanelVisible(false);

			setNewMessageReplyTarget(null);
			setNewMessageImage(null);
		};

		const resetMessageParams = () => {
			setNewMessageText("");
			setNewMessageReplyTarget(null);
			setNewMessageImage(null);
			setNewMessageImageUrl(null);
			setHighlightedPanelVisible(false);
		};

		const sendNewMessage = () => {
			if (connection) {
				if (newMessageText !== "") {
					if (newMessageImage) {
						const form = new FormData();
						form.append("file", newMessageImage);
						form.append("Message", newMessageText);
						form.append("DialogueId", currentChat.id);

						sendFileMessage(form)
							.then(() => {
								resetMessageParams();
							})
							.catch((error) => {
								throw new Error(error);
							});
					} else if (newMessageReplyTarget) {
						sendReplyMessage(
							currentChat.id,
							newMessageReplyTarget.id,
							newMessageText
						)
							.then(() => {
								resetMessageParams();
							})
							.catch((error) => {
								throw new Error(error);
							});
					} else {
						sendMessage(currentChat.id, newMessageText)
							.then(() => {
								resetMessageParams();
							})
							.catch((error) => {
								throw new Error(error);
							});
					}
				} else {
					setCanSend(false);
					setTimeout(() => {
						setCanSend(true);
					}, 200);
				}
			}
			scrollToBottom();
		};

		const sendMessageEnter = (event) => {
			if (event.key === "Enter") {
				sendNewMessage();
			}
		};

		const confirmDeleteMessage = () => {
			deleteMessage(messageToDeleteId).then(() => {
				setDeleteMessageModalOpen(false);
				setMessageToDeleteId(null);
			});
		};

		const unpinPinnedMessage = () => {
			if (pinnedMessages.length > 0) {
				unpinMessage(pinnedMessages[0].iD).then(() =>
					setUnpinMessageModalOpen(false)
				);
			}
		};

		const scrollToSpecificMessage = async (messageId, behaivor, position) => {
			const scrollMessage = document.getElementById(messageId);
			setIsScrolling(true);

			if (scrollMessage) {
				scrollMessage.scrollIntoView({ behavior: behaivor, block: position });
				setIsScrolling(false);
			}
		};

		const groupMessages = (messages) => {
			const groupedMessages = {};

			messages.forEach((message) => {
				if (message && message.craeteDateTime) {
					const messageDate = formatDate(
						message.craeteDateTime,
						null,
						false,
						true
					);

					if (!groupedMessages[messageDate]) {
						groupedMessages[messageDate] = {
							date: formatDate(message.craeteDateTime),
							messages: [],
						};
					}

					groupedMessages[messageDate].messages.push(message);
				}
			});

			const result = Object.values(groupedMessages);

			return result;
		};

		const scrollToBottom = () => {
			if (messagesContainerRef.current) {
				messagesContainerRef.current.scrollTop =
					messagesContainerRef.current.scrollHeight;
			}
		};

		useEffect(() => {
			if (currentChat && connection && currentChatMessages) {
				const addMessageHandler = (newMessage) => {
					if (newMessage.dialogueId === currentChat.id) {
						setCurrentChatMessages((prevMessages) => {
							const updatedMessages = [newMessage, ...prevMessages];
							return updatedMessages;
						});
					}
				};

				const deleteMessageHandler = (messageToRemoveData) => {
					setCurrentChatMessages((prevMessages) => {
						const updatedMessages = prevMessages.filter(
							(message) => message.id !== messageToRemoveData.idMessage
						);
						return updatedMessages;
					});
				};

				const pinUnpinMessageHandler = (pinnedMessagesData) => {
					if (pinnedMessagesData.atached) {
						const newPinnedMessage = currentChatMessages.find(
							(message) => message.id === pinnedMessagesData.messageId
						);
						setPinnedMessages([
							{ iD: newPinnedMessage.id, message: newPinnedMessage.message },
						]);
					} else {
						setPinnedMessages([]);
					}
				};

				connection.on("Add", addMessageHandler);
				connection.on("Delete", deleteMessageHandler);
				connection.on("Attached", pinUnpinMessageHandler);

				return () => {
					connection.off("Add", addMessageHandler);
					connection.off("Delete", deleteMessageHandler);
					connection.off("Attached", pinUnpinMessageHandler);
				};
			}
		}, [currentChat, connection, currentChatMessages]);

		useEffect(() => {
			if (currentChat) {
				setPreloaderVisible(true);
				getAllSpecificChatMessages(currentChat.id)
					.then((response) => {
						setCurrentChatMessages(response.data);

						getSpecificChatPinnedMessages(currentChat.id).then((response) => {
							setPinnedMessages(response.data);
						});
					})
					.then(() => {
						setTimeout(() => {
							setPreloaderVisible(false);
						}, 200); // Ждет, пока сообщения отрендерятся
					});

				setNewMessageReplyTarget(null);
			}
		}, [currentChat]);

		useEffect(() => {
			if (currentChatMessages) {
				setGroupedMessages(() => {
					return groupMessages(currentChatMessages);
				});
			}
		}, [currentChatMessages]);

		return (
			<div
				className={`chat__window ${
					currentScreenWidth > mobileScreenBreakpoint || mobileChatWindowOpen
						? "open"
						: ""
				} ${currentChat && currentChat.userAmount ? "group-chat" : ""}`}>
				<Preloader
					isVisible={currentChat && preloaderVisible}
					addClass={"chat__window-preloader"}
				/>
				<ChatConfirmationModal
					text={"Вы действительно хотите открепить сообщение для всех?"}
					isOpen={unpinMessageModalOpen}
					setModalOpen={setUnpinMessageModalOpen}
					onConfirm={unpinPinnedMessage}
					answer1={"Отменить"}
					answer2={"Открепить"}
				/>
				<ChatConfirmationModal
					text={"Вы действительно хотите удалить сообщение для всех?"}
					isOpen={deleteMessageModalOpen}
					setModalOpen={setDeleteMessageModalOpen}
					onConfirm={confirmDeleteMessage}
					answer1={"Отменить"}
					answer2={"Удалить"}
				/>
				{currentChat ? (
					<div className="chat__window-current-chat">
						<div className="chat__window-current-chat-header">
							<div className="chat__window-current-chat-header-info">
								<img
									className="chat__window-current-chat-back"
									src="images/buttons/arrow-left.svg"
									alt="Назад"
									onClick={() => swipeBack()}
								/>
								{currentChat.avatar ? (
									<img
										className="chat__window-current-chat-avatar"
										src="images/placeholder.png"
										alt="Аватар чата"
									/>
								) : (
									<ImageLessAvatar
										name={currentChat.userName.split(" ")[0]}
										surname={currentChat.userName.split(" ")[1]}
										addClass="chat__window-current-chat-avatar"
									/>
								)}
								<div>
									<h2>{currentChat.userName}</h2>
								</div>
							</div>
							<img src="/images/chat/tri-menu.svg" alt="Меню чата" />
						</div>
						{pinnedMessages && pinnedMessages.length > 0 && (
							<div className="chat__window-current-chat-highlighted-panel_container pinned-message">
								<div
									className="chat__window-current-chat-highlighted-panel"
									onClick={() =>
										scrollToSpecificMessage(
											pinnedMessages[0].iD,
											"smooth",
											"center"
										)
									}>
									<div className="chat__window-current-chat-highlighted-panel_info">
										<h4>Закрепленное сообщение</h4>
										<p>{pinnedMessages[0].message}</p>
									</div>
								</div>
								<img
									src="/images/buttons/X-gray.svg"
									alt="Отмена"
									onClick={() => setUnpinMessageModalOpen(true)}
								/>
							</div>
						)}
						<div
							className="chat__window-current-chat-messages"
							{...swipeHandlers}
							ref={messagesContainerRef}>
							{groupedMessages && groupedMessages.length > 0 ? (
								<>
									{groupedMessages.map((group, index) => (
										<div
											ref={messageDateContainerRef}
											key={`${group.date}-${index}`}
											className="chat__window-date-block-messages">
											{group.messages.map((message, index) => (
												<>
													{message.id && (
														<ChatMessage
															key={`${message.id}`}
															currentUserId={currentUserId}
															connection={connection}
															setDeleteMessageModalOpen={
																setDeleteMessageModalOpen
															}
															currentChat={currentChat}
															message={message}
															openMessageMenuId={openMessageMenuId}
															handleOpenMessageMenu={handleOpenMessageMenu}
															newMessageReplyTarget={newMessageReplyTarget}
															setNewMessageReplyTarget={
																setNewMessageReplyTarget
															}
															pinnedMessages={pinnedMessages}
															setMessageToDeleteId={setMessageToDeleteId}
															scrollToSpecificMessage={scrollToSpecificMessage}
															setHighlightedPanelVisible={
																setHighlightedPanelVisible
															}
														/>
													)}
												</>
											))}
											{group.date && (
												<span className="chat__window-date-block-messages-date">
													{group.date}
												</span>
											)}
										</div>
									))}
								</>
							) : (
								<div
									style={{
										width: "100%",
										height: "100%",
										display: "flex",
										justifyContent: "center",
									}}>
									<p className="chat__window-date-block-messages-date">
										Сообщений ещё нет
									</p>
								</div>
							)}
						</div>
						<div className="chat__window-current-chat-bottom-wrapper">
							{highlightedPanelVisible && (
								<div className="chat__window-current-chat-highlighted-panel-wrapper">
									{newMessageReplyTarget ? (
										<>
											<div className="chat__window-current-chat-highlighted-panel">
												<img
													src="/images/icons/reply-blue.svg"
													alt="Ответить"
												/>
												<div className="chat__window-current-chat-highlighted-panel_info">
													<h4>
														Ответить{" "}
														{currentUserId === newMessageReplyTarget.userid
															? "на своё сообщение"
															: currentChat.userName}
													</h4>
													<p>{newMessageReplyTarget.message}</p>
												</div>
											</div>
										</>
									) : newMessageImage ? (
										<div className="chat__window-attached-image">
											<span className="panel-title">Файл прикреплен:</span>
											<div>
												<img
													src={newMessageImageUrl}
													alt="Прикрепленное сообщение"
												/>
												<p>
													<span className="image-name">
														{newMessageImage.name.split(".")[0]}
													</span>
													<span className="extension">
														.{newMessageImage.name.split(".")[1]}
													</span>
												</p>
											</div>
										</div>
									) : (
										<></>
									)}
									<img
										src="/images/buttons/X-gray.svg"
										alt="Отмена"
										onClick={closeHighlightedPanel}
									/>
								</div>
							)}
							<div className="chat__window-current-chat-bottom">
								<div className="chat__window-current-chat-load-file">
									<input
										type="file"
										accept=".jpg, .jpeg, .png"
										onChange={(e) => attachFile(e)}
									/>
									<img src="/images/chat/file.svg" alt="Прикрепить" />
								</div>

								<div
									className={`chat__window-current-chat-bottom-input ${
										!canSend ? "not-allowed" : ""
									}`}>
									<input
										type="text"
										placeholder="Текст сообщения"
										value={newMessageText}
										onKeyDown={(e) => sendMessageEnter(e)}
										onInput={(e) => setNewMessageText(e.target.value)}
									/>
									<img src="/images/chat/smile.svg" alt="Эмодзи" />
								</div>
								<img
									className="chat__window-current-chat-bottom-send-button"
									src="/images/chat/send.svg"
									alt="Отправить"
									onClick={sendNewMessage}
								/>
							</div>
						</div>
					</div>
				) : (
					<div className="chat__window-no-selected">
						<img src="images/chat/no-selected-chat.svg" alt="Выберите диалог" />
						<p>Выберите диалог</p>
					</div>
				)}
			</div>
		);
	}
);

const ChatMessage = React.memo(
	({
		connection,
		currentUserId,
		message,
		openMessageMenuId,
		handleOpenMessageMenu,
		setNewMessageReplyTarget,
		scrollToSpecificMessage,
		setDeleteMessageModalOpen,
		pinnedMessages,
		setMessageToDeleteId,
		setHighlightedPanelVisible,
	}) => {
		const [messageTime, setMessageTime] = useState(null);
		const [messageTextCopied, setMessageTextCopied] = useState(false);

		const [isChecked, setIsChecked] = useState(message.check);

		const [replyContentWidth, setReplyContentWidth] = useState("initial");

		const messageRef = useRef(null);
		const containerRef = useRef(null);

		const replyContent = useRef(null);
		const messageTextRef = useRef(null);

		const checkMessageInView = () => {
			if (!message.check && message.userid !== currentUserId) {
				checkNewMessage(message.id);
			}
		};

		const handleReplyMenu = (event) => {
			setNewMessageReplyTarget(message);
			handleOpenMessageMenu(message.id, event);
			setHighlightedPanelVisible(true);
		};

		const handleCopyMessage = (textToCopy, event) => {
			navigator.clipboard
				.writeText(textToCopy)
				.then(() => {
					setMessageTextCopied(true);
					setTimeout(() => setMessageTextCopied(false), 2000); //Для плашки о том, что текст скопирован
				})
				.catch((err) => {
					console.error("Failed to copy text: ", err);
				});

			handleOpenMessageMenu(message.id, event);
		};

		const handleDeleteMessage = (event) => {
			setMessageToDeleteId(message.id);
			setDeleteMessageModalOpen(true);
			handleOpenMessageMenu(message.id, event);
		};

		const handlePinMessage = (event) => {
			pinMessage(message.id).then(() =>
				handleOpenMessageMenu(message.id, event)
			);
		};
		useEffect(() => {
			if (message.msgType === 2 && messageTextRef) {
				setReplyContentWidth(messageTextRef.current.clientWidth + 30);
			}
		}, [messageRef, message]);

		useEffect(() => {
			formatDate(message.craeteDateTime, setMessageTime, true);
		}, []);

		useEffect(() => {
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							checkMessageInView(entry);
						}
					});
				},
				{
					root: containerRef.current,
					rootMargin: "0px",
					threshold: 0.1,
				}
			);

			if (messageRef.current) {
				observer.observe(messageRef.current);
			}

			return () => {
				if (messageRef.current) {
					observer.unobserve(messageRef.current);
				}
			};
		}, [message.id]);

		useEffect(() => {
			if (connection) {
				const checkHandler = (id, checkerId) => {
					if (checkerId !== currentUserId) {
						setIsChecked(true);
					}
				};

				connection.on("Check", checkHandler);

				return () => {
					connection.off("Check", () => checkHandler);
				};
			}
		}, [connection]);

		return (
			<div
				ref={messageRef}
				id={message.id}
				className={`chat__message ${
					message.userid === currentUserId ? "current-user" : ""
				} ${
					message.msgType === 1
						? "file-message"
						: message.msgType === 2
						? "reply-message"
						: ""
				}`}>
				<img
					className="chat__message-user-avatar"
					src="images/placeholder.png"
					alt="Аватар"
				/>
				<div className="chat__message-content">
					{message.msgType === 2 && message.rederectMessage !== "" && (
						<div
							ref={replyContent}
							style={{ width: replyContentWidth }}
							className="chat__message-reply-content"
							onClick={() =>
								scrollToSpecificMessage(
									message.rederectUserId,
									"smooth",
									"center"
								)
							}>
							{/* <h4>Имя отправителя</h4> */}
							<p>{message.rederectMessage}</p>
						</div>
					)}
					<div className="chat__message-content-top">
						<div className="chat__message-header">
							<h4>{message.sender}</h4>
							<span onClick={(e) => handleOpenMessageMenu(message.id, e)}>
								<img src="/images/chat/tri-menu.svg" alt="Меню сообщения" />
							</span>
							<div
								className={`chat__message-menu ${
									openMessageMenuId === message.id ? "open" : ""
								}`}>
								<span onClick={(e) => handleReplyMenu(e)}>
									<img src="/images/icons/reply.svg" alt="Ответить" />
									Ответить
								</span>
								<span onClick={(e) => handleCopyMessage(message.message, e)}>
									<img src="/images/icons/copy-message.svg" alt="Удалить" />
									Скопировать
								</span>
								<span>
									<img
										src="/images/icons/send-to-another-dialogue.svg"
										alt="Удалить"
									/>
									Переслать
								</span>
								{pinnedMessages && pinnedMessages.length === 0 && (
									<span onClick={(e) => handlePinMessage(e)}>
										<img src="/images/icons/pin-message.svg" alt="Удалить" />
										Закрепить
									</span>
								)}
								{message.userid === currentUserId && (
									<span onClick={handleDeleteMessage}>
										<img src="/images/icons/delete-message.svg" alt="Удалить" />
										Удалить у всех
									</span>
								)}
							</div>
						</div>
						{message.msgType === 1 && (
							<div className="chat__message-images">
								{message.urlImages.map((image, index) => (
									<img
										key={`${message.id}-${index}`}
										src={image}
										alt="Изображение"
									/>
								))}
							</div>
						)}
						<p ref={messageTextRef}>{message.message}</p>
					</div>
					<span>
						{messageTime}{" "}
						{message.userid === currentUserId && (
							<>
								{isChecked ? (
									<img src="/images/chat/status-read.svg" alt="Прочитано" />
								) : (
									<img src="/images/chat/status-sent.svg" alt="Отправлено" />
								)}
							</>
						)}
					</span>
				</div>
			</div>
		);
	}
);

const ChatConfirmationModal = ({
	text,
	isOpen,
	setModalOpen,
	onConfirm,
	answer1,
	answer2,
}) => {
	const closeModal = () => {
		onConfirm();
		setModalOpen(false);
	};

	return (
		<div className={`chat__modal-window-wrapper ${isOpen ? "open" : ""}`}>
			<div className="chat__modal-window">
				<div className="chat__modal-window-conent">
					<p>{text}</p>
				</div>
				<div className="chat__modal-window-buttons">
					<button className="cancel" onClick={() => setModalOpen(false)}>
						{answer1}
					</button>
					<button className="confirm" onClick={closeModal}>
						{answer2}
					</button>
				</div>
			</div>
		</div>
	);
};
