import {useParams, useLocation, useNavigate} from 'react-router-dom';
import React, {useState} from "react";
import Slider from "react-slick";


export const Header_product = () => {
    const navigate = useNavigate();
    const openModalMenu = () => setIsModalMenuOpen(true);
    const closeModalMenu = () => setIsModalMenuOpen(false);
    const [isModalMenuOpen, setIsModalMenuOpen] = useState(false);
    const copyLink = () => {
        navigator.clipboard.writeText(window.location.href)
            .then(() => alert('Ссылка скопирована в буфер обмена'))
            .catch((error) => console.log('Ошибка при копировании ссылки:', error));
    };
    const shareLink = () => {
        if (navigator.share) {
            navigator.share({
                title: document.title,
                text: 'Посмотрите этот сайт!',
                url: window.location.href,
            })
                .then(() => console.log('Ссылка успешно отправлена'))
                .catch((error) => console.log('Ошибка при отправке ссылки:', error));
        } else {
            copyLink();
        }
    };

    return (
        <div className="step step-1" style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
            <button className="back-button" onClick={() => {
                navigate("/");
            }}></button>
            <div className="icons prod">
                <button className="icon-button" onClick={shareLink}></button>
                <button className="icon-button"></button>
                <button className="icon-button"></button>
                <button className="icon-button" onClick={openModalMenu}></button>
            </div>
            {isModalMenuOpen && (
                <div className="modal">
                    <div className="modal">
                        <div className="modal-menu">
                            <div className="modal-menu-header" style={{height: "100%"}}>
                                <div className="modal-stroke">Пожаловаться на объявление</div>
                            </div>

                            <button className="apply-btn" onClick={closeModalMenu}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const prices = [
    {name: 'Размещение поста', duration: '60 мин', about: 'Персональная тренировка'},
    {name: 'Групповая тренировка', duration: '90 мин', about: 'Групповое занятие'}, {
        name: 'Групповая тренировка',
        duration: '90 мин',
        about: 'Групповое занятие'
    },
    {name: 'Групповая тренировка', duration: '90 мин', about: 'Групповое занятие'},
];
const predloj = [
    {day: "Пн", dataPR: "Ежедневно", time: "12:00 - 21:00", price: "3600"},
    {day: "Вс", dataPR: "12", time: "12", price: "12"},
    {day: "Ср", dataPR: "12", time: "12", price: "12"}
];
const daysOrder = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
const sortedPredloj = predloj.sort((a, b) => {
    return daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
});
const mes = [
    {month: "Январь"}, {month: "Февраль"}, {month: "Март"}, {month: "Апрель"},
];

const Stars = ({rating}) => {
    const fullStars = Math.floor(rating); // Полные звёзды
    const halfStar = rating % 1 !== 0; // Половинка звезды
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Пустые звёзды

    return (
        <div className="stars">
            {/* Полные звёзды */}
            {[...Array(fullStars)].map((_, index) => (
                <img key={index} src="/star-light.svg" alt="star" className="star"/>
            ))}
            {/* Половинка звезды */}
            {halfStar && <img src="/star.svg" alt="half-star" className="star"/>}
            {/* Пустые звёзды */}
            {[...Array(emptyStars)].map((_, index) => (
                <img key={index} src="/star.svg" alt="empty-star" className="star"/>
            ))}
        </div>
    );
};

const ReviewBlock = ({reviews, starLight, starDark}) => {
    return (
        <div className="reviews-container">
            <div className="reviews-header">
                <h2>Отзывы клиентов</h2>
                <div className="rating-summary">
                    <span className="rating">4.2</span>
                    <span className="reviews-count">(отзывов: 12)</span>
                </div>
                <button className="add-review-btn">+ Добавить отзыв</button>
            </div>

            {reviews.map((review, index) => (
                <div key={index} className="review">
                    <div className="review-header">
                        <img src={review.profileImage} alt={review.author} className="profile-photo"/>
                        <div className="review-info">
                            <span className="review-author">{review.author}</span>
                            <span className="review-date">{review.date}</span>
                        </div>
                    </div>
                    <div className="review-rating">
                        <Stars rating={review.rating} starLight={starLight} starDark={starDark}/>
                        <span className="ratings">4.2</span>
                        <span className="deal-status">Сделка состоялась</span>
                    </div>
                    <p className="review-text">
                        {review.text}
                        <a href="#" className="read-more">Читать далее</a>
                    </p>
                    <div className="review-footer">
                        <span className="likes"><p></p> {review.likes}</span>
                        <span className="dislikes"><p></p> {review.dislikes}</span>
                    </div>
                </div>
            ))}

            <button className="view-all-btn">Посмотреть все</button>
        </div>
    );
};

export const CoachItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>

                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            <div className="uslAndPrice">
                <h1>Услуги и цены</h1>

                {visiblePrices.map((price, index) => (
                    <div key={index} style={{marginTop: 4}} className="prices">
                        <div>
                            <span>{price.name}</span>
                            <p>{price.duration}</p>
                        </div>
                        <span>{price.about ? price.about : 'Не указано'}</span>
                    </div>
                ))}

                {prices.length > 1 && (
                    <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{cursor: 'pointer'}}>
                        {isExpanded2 ? 'Скрыть' : 'Все услуги'}
                    </div>
                )}
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>

                <span>Специализация <p>{about}</p></span>
                <span>Опыт работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const InsuranceItem= () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>

                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>


                <span>ИНН организации <p>{about}</p></span>
                <span>Лицензия Банка России <p>{about}</p></span>
                <span>Адрес <p>{about}</p></span>
                <span>Дополнительный телефон <a style={{color: "#283DC1", textDecoration: "none"}}
                                                href="">{about}</a></span>
                <span>Сайт <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>


                <span>Режим работы: <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const GoalKeeperItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>

                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            <div className="uslAndPrice">
                <h1>Услуги и цены</h1>

                {visiblePrices.map((price, index) => (
                    <div key={index} style={{marginTop: 4}} className="prices">
                        <div>
                            <span>{price.name}</span>
                            <p>{price.duration}</p>
                        </div>
                        <span>{price.about ? price.about : 'Не указано'}</span>
                    </div>
                ))}

                {prices.length > 1 && (
                    <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{cursor: 'pointer'}}>
                        {isExpanded2 ? 'Скрыть' : 'Все услуги'}
                    </div>
                )}
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>


                <span>Уровень вратаря <p>{about}</p></span>
                <span>Опыт <p>{about}</p></span>
                <span>Стоимость <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Режим работы</h1>*/}


            {/*    <span>Режим работы: <p>{about}</p></span>*/}
            {/*</div>*/}

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const RefereeItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>

                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            <div className="uslAndPrice">
                <h1>Услуги и цены</h1>

                {visiblePrices.map((price, index) => (
                    <div key={index} style={{marginTop: 4}} className="prices">
                        <div>
                            <span>{price.name}</span>
                            <p>{price.duration}</p>
                        </div>
                        <span>{price.about ? price.about : 'Не указано'}</span>
                    </div>
                ))}

                {prices.length > 1 && (
                    <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{cursor: 'pointer'}}>
                        {isExpanded2 ? 'Скрыть' : 'Все услуги'}
                    </div>
                )}
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>


                <span>Категория <p>{about}</p></span>
                <span>Опыт <p>{about}</p></span>
                <span>Стоимость <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>


                <span>Режим работы <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const BloggerItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            <div className="uslAndPrice">
                <h1>Услуги и цены</h1>

                {visiblePrices.map((price, index) => (
                    <div key={index} style={{marginTop: 4}} className="prices">
                        <div>
                            <span>{price.name}</span>
                            <p>{price.duration}</p>
                        </div>
                        <span>{price.about ? price.about : 'Не указано'}</span>
                    </div>
                ))}

                {prices.length > 1 && (
                    <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{cursor: 'pointer'}}>
                        {isExpanded2 ? 'Скрыть' : 'Все услуги'}
                    </div>
                )}
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>


                <span>Количество подписчиков <p>{about}</p></span>
                <span>Сайт <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Вконтакте <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Дзен <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Youtube <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Telegram <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Дополнительный телефон <a style={{color: "#283DC1", textDecoration: "none"}}
                                                href="">{about}</a></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Режим работы</h1>*/}


            {/*    <span>Режим работы <p>{about}</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>


                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const EquipmentRepairItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>


                <span>ИНН организации <p>{about}</p></span>
                <span>Категория <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Сайт <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Дополнительный телефон <a style={{color: "#283DC1", textDecoration: "none"}}
                                                href="">{about}</a></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>


                <span>Режим работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>


                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const EquipmentTailoringItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>


                <span>ИНН организации <p>{about}</p></span>
                <span>Сайт <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Дополнительный телефон <a style={{color: "#283DC1", textDecoration: "none"}}
                                                href="">{about}</a></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>


                <span>Режим работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>


                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const DryCleaningItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>


                <span>ИНН организации <p>{about}</p></span>
                <span>Сайт <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Дополнительный телефон <a style={{color: "#283DC1", textDecoration: "none"}}
                                                href="">{about}</a></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>


                <span>Режим работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>


                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const TournamentItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>

                <span>Тип соревнования <p>{about}</p></span>
                <span>Период проведения <p>{about}</p></span>
                <span>Возрастная категория <p>{about}</p></span>
                <span>Формат <p>{about}</p></span>
                <span>Ледовая арена <p>{about}</p></span>
                <span>Адрес ледовой арены <p>{about}</p></span>
                <span>Следж-хоккей <p>{about}</p></span>
                <span>Сайт <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Дополнительный телефон <a style={{color: "#283DC1", textDecoration: "none"}}
                                                href="">{about}</a></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>


                <span>Режим работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>


                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const EquipmentItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>

                <span>Категория <p>{about}</p></span>
                <span>Состояние <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>

                <span>Режим работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>

                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const TrainingItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>

                <span>Дата начала <p>{about}</p></span>
                <span>Дата завершения <p>{about}</p></span>
                <span>Возрастная категория <p>{about}</p></span>
                <span>Уровень игроков <p>{about}</p></span>
                <span>Следж-хоккей <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>

                <span>Режим работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>

                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const RentItem = ({starLight, starDark}) => {
    const {id} = useParams();
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedTimes, setSelectedTimes] = useState([]);
    const toggleTimeSelection = (time, price) => {
        if (selectedTimes.includes(time)) {

            setSelectedTimes(selectedTimes.filter(t => t !== time));
            setTotalPrice(prevPrice => prevPrice - price);
        } else {

            setSelectedTimes([...selectedTimes, time]);
            setTotalPrice(prevPrice => prevPrice + price);
        }
    };
    const [isModalMenuOpen, setIsModalMenuOpen] = useState(false);
    const openModalMenu = () => setIsModalMenuOpen(true);
    const closeModalMenu = () => setIsModalMenuOpen(false);

    const [today, setToday] = useState({
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
    });
    const months = [
        {month: 'Январь', days: 31},
        {month: 'Февраль', days: 28},
        {month: 'Март', days: 31},
        {month: 'Апрель', days: 30},
        {month: 'Май', days: 31},
        {month: 'Июнь', days: 30},
        {month: 'Июль', days: 31},
        {month: 'Август', days: 31},
        {month: 'Сентябрь', days: 30},
        {month: 'Октябрь', days: 30},
        {month: 'Ноябрь', days: 30},
        {month: 'Декабрь', days: 31},
    ];
    const reviews = [
        {
            profileImage: 'user-image.jpg',
            author: 'Виктор',
            date: '23.09.2024',
            text: 'Силовая тренировкаСиловая тренировкаСиловая тренировка...',
            likes: 321,
            dislikes: 321,
            rating: 4.5
        },
        {
            profileImage: 'user-image.jpg',
            author: 'Виктор',
            date: '23.09.2024',
            text: 'Силовая тренировкаСиловая тренировкаСиловая тренировка...',
            likes: 321,
            dislikes: 321,
            rating: 4.5
        },
        {
            profileImage: 'user-image.jpg',
            author: 'Виктор',
            date: '23.09.2024',
            text: 'Силовая тренировкаСиловая тренировкаСиловая тренировка...',
            likes: 321,
            dislikes: 321,
            rating: 4.5
        },
        {
            profileImage: 'user-image.jpg',
            author: 'Виктор',
            date: '23.09.2024',
            text: 'Силовая тренировкаСиловая тренировкаСиловая тренировка...',
            likes: 321,
            dislikes: 321,
            rating: 4.5
        },
    ];
    const timeOptions = {
        1: [{time: '12:00', price: 25000}, {time: '14:00', price: 30000}],
        2: [{time: '10:00', price: 20000}, {time: '16:00', price: 27000}],
    };
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const openFullscreen = (index) => {
        setCurrentSlide(index);
        setIsFullscreen(true);
    };
    const closeFullscreen = () => {
        setIsFullscreen(false);
    };
    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: currentSlide,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
        setCurrentSlide(current);
    };
    const weekDays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    const getDayOfWeek = (year, month, day) => {
        const date = new Date(year, month, day);
        return weekDays[date.getDay()];
    };
    const getFirstDayOfWeek = (year, month) => {
        const firstDay = new Date(year, month, 1);
        return firstDay.getDay();
    };
    const rotateWeekDays = (offset) => {
        const rotatedDays = [...weekDays];
        for (let i = 0; i < offset; i++) {
            rotatedDays.push(rotatedDays.shift());
        }
        return rotatedDays;
    };
    const selectMonth = (index) => {
        setSelectedMonth(index);
        setExpanded(false);
        setSelectedDay(null);
    };
    const selectDay = (day) => {
        setSelectedDay(day);
    };
    const toggleExpand3 = () => {
        setExpanded(!expanded);
    };
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };
    const handleApplySort = () => {
        setIsSortModalOpen(false);
    };
    const [totalPrice, setTotalPrice] = useState(0);
    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);
    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const [selectedTab, setSelectedTab] = useState('All');
    const [isSortModalOpen, setIsSortModalOpen] = useState(false);
    const openSortModal = () => setIsSortModalOpen(true);
    const closeSortModal = () => setIsSortModalOpen(false);
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);
    const [sortOption, setSortOption] = useState('date');
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const [sortType, setSortType] = useState(null);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    const toggleOpen1 = () => {
        setIsOpen1(!isOpen1);
    };
    const toggleOpen2 = () => {
        setIsOpen2(!isOpen2);
    };
    if (!products) {
        return <div>Объявление не найдено</div>;
    }
    console.log('Found product:', product);
    if (!product) {
        return <div>Продукт не найден</div>;
    }
    const getLowestPriceFromApplications = (applications) => {

        const prices = applications.flatMap(app =>
            app.sessions.flatMap(session => [
                // parseInt(session.prepay.replace(' р', ''), 10),
                parseInt(session.onSpot.replace(' р', ''), 10)
            ])
        );


        if (prices.length === 0) {
            return null;
        }


        return Math.min(...prices);
    };
    const applications = [
        {
            id: 1,
            status: 'Approved',
            date: '23.01.24',
            time: '15:20',
            orderNumber: '02345643',
            user: 'Александр Тимофеевич Брынза',
            sessions: [
                {day: 'Пн', date: '23.01.2024', time: '18:30 - 19:30', prepay: '2000 р', onSpot: '23000 р'},
            ],
            comment: '',
            imgUrl: '/path/to/image.jpg',
        },
        {
            id: 2,
            status: 'Pending',
            date: '23.01.24',
            time: '15:20',
            orderNumber: '02345644',
            user: 'Александр Тимофеевич Брынза',
            sessions: [
                {day: 'Пн', date: '23.01.2024', time: '21:30 - 22:30', prepay: '2000 р', onSpot: '19000 р'},
            ],
            comment: '',
            imgUrl: '/path/to/image.jpg',
        }, {
            id: 3,
            status: 'Not Approved',
            date: '23.01.24',
            time: '15:20',
            orderNumber: '02345644',
            user: 'Александр Тимофеевич Брынза',
            sessions: [
                {day: 'Пн', date: '23.01.2024', time: '21:30 - 22:30', prepay: '2000 р', onSpot: '21000 р'},
            ],
            comment: '',
            imgUrl: '/path/to/image.jpg',
        },
    ];
    const lowestPrice = getLowestPriceFromApplications(applications);
    const getFilteredApplications = () => {
        const now = new Date();

        return applications
            .filter(app => (selectedTab === 'All' ? true : app.status === selectedTab))
            .filter(app => {
                const appDate = new Date(app.date);

                switch (sortType) {
                    case 'all-time':
                        return true;
                    case 'year':
                        return appDate >= new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
                    case 'half-year':
                        return appDate >= new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
                    case 'quarter':
                        return appDate >= new Date(now.getFullYear(), now.getMonth() - 3, now.getDate());
                    case 'week':
                        return appDate >= new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
                    case 'today':
                        return (
                            appDate.getDate() === now.getDate() &&
                            appDate.getMonth() === now.getMonth() &&
                            appDate.getFullYear() === now.getFullYear()
                        );
                    default:
                        return true;
                }
            });
    };
    const sortedApplications = getFilteredApplications();
    const handleAccept = (id) => {
        console.log(`Accepted application with id: ${id}`);
    };
    const handleReject = (id) => {
        console.log(`Rejected application with id: ${id}`);
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(false)}>
                {images && images.map((image, i) => (
                    <img
                        src={image}
                        alt={`Product Image ${i + 1}`}
                        key={i}
                        onClick={() => openFullscreen(i)}
                        style={{cursor: 'pointer'}}
                    />
                ))}
            </Slider>
            {isFullscreen && (
                <div className="fullscreen-overlay">
                    <div className="header__fullscreen">
                        <div className="leftside__full">
                            <button className="back-button" onClick={closeFullscreen}></button>
                            <span>{currentSlide + 1} из {images.length}</span>
                        </div>

                        <div className="rightside__full">
                            <a className="call sphere__icon"></a>
                            <a className="mail sphere__icon"></a>
                            {/*<a href="" className="dots__full"></a>*/}
                        </div>
                    </div>

                    <Slider {...settings(true)}>
                        {images && images.map((image, i) => (
                            <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                        ))}
                    </Slider>
                </div>
            )}
            <div className="slider-counter">
                {currentSlide + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>
                    {lowestPrice !== null ? `От ${lowestPrice} р за услугу` : "Информации нет"}
                </p>
                <h1>{name}</h1>
                <p className="rating">
                    <span>{product.rating}</span> (отзывов: 12)
                </p>
                <h2>{location}</h2>
                <a href={about}>Показать на карте</a>

                <div className="izb"></div>
            </div>
            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>
            <div className="product__admin">
                <div onClick={toggleOpen1}><h1>Статус заявок</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen1 ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="tabs">
                        <button
                            onClick={() => setSelectedTab('All')}
                            className={selectedTab === 'All' ? 'active' : ''}
                        >
                            Все заявки
                        </button>
                        <button
                            onClick={() => setSelectedTab('Pending')}
                            className={selectedTab === 'Pending' ? 'active' : ''}
                        >
                            На рассмотрении
                        </button>
                        <button
                            onClick={() => setSelectedTab('Approved')}
                            className={selectedTab === 'Approved' ? 'active' : ''}
                        >
                            Согласовано
                        </button>
                        <button
                            onClick={() => setSelectedTab('Not Approved')}
                            className={selectedTab === 'Not Approved' ? 'active' : ''}
                        >
                            Не согласовано
                        </button>
                    </div>
                    <div className="step step-5" style={{marginBottom: 0}}>
                        <div className="custom-select">
                            <button className="sort-button" onClick={openSortModal}><span></span> Сортировка</button>
                        </div>
                        <button className="notify-button"><img src="/Group.svg" alt=""/> Уведомлять о заявках</button>
                    </div>

                    {isSortModalOpen && (
                        <div className="modal">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button className="back-button" onClick={closeSortModal}></button>
                                    <h2 style={{marginTop: 0, marginLeft: 24}}>Сортировка</h2>
                                    <button className="clear-btn" onClick={() => setSortType(null)}>Очистить</button>
                                </div>
                                <div className="modal-body">
                                    <div className="radio-group" style={{flexDirection: 'row'}}>
                                        <input
                                            type="radio"
                                            id="price-asc"
                                            name="sortType"
                                            value="all-time"
                                            checked={sortType === 'all-time'}
                                            onChange={(e) => setSortType(e.target.value)}
                                        />
                                        <label htmlFor="all-time">За все время</label>
                                    </div>
                                    <div className="radio-group" style={{flexDirection: 'row', marginTop: 0}}>
                                        <input
                                            type="radio"
                                            id="price-asc"
                                            name="sortType"
                                            value="year"
                                            checked={sortType === 'year'}
                                            onChange={(e) => setSortType(e.target.value)}
                                        />
                                        <label htmlFor="year">За год</label>
                                    </div>
                                    <div className="radio-group" style={{flexDirection: 'row'}}>
                                        <input
                                            type="radio"
                                            id="price-desc"
                                            name="sortType"
                                            value="half-year"
                                            checked={sortType === 'half-year'}
                                            onChange={(e) => setSortType(e.target.value)}
                                        />
                                        <label htmlFor="half-year">За полгода</label>
                                    </div>
                                    <div className="radio-group" style={{flexDirection: 'row'}}>
                                        <input
                                            type="radio"
                                            id="date"
                                            name="sortType"
                                            value="quarter"
                                            checked={sortType === 'quarter'}
                                            onChange={(e) => setSortType(e.target.value)}
                                        />
                                        <label htmlFor="quarter">За квартал</label>
                                    </div>
                                    <div className="radio-group" style={{flexDirection: 'row'}}>
                                        <input
                                            type="radio"
                                            id="status"
                                            name="sortType"
                                            value="week"
                                            checked={sortType === 'week'}
                                            onChange={(e) => setSortType(e.target.value)}
                                        />
                                        <label htmlFor="week">За неделю</label>
                                    </div>
                                    <div className="radio-group" style={{flexDirection: 'row'}}>
                                        <input
                                            type="radio"
                                            id="status"
                                            name="sortType"
                                            value="today"
                                            checked={sortType === 'today'}
                                            onChange={(e) => setSortType(e.target.value)}
                                        />
                                        <label htmlFor="today">За сегодня</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="apply-btn" onClick={handleApplySort}>Применить сортировку
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}


                    {sortedApplications.map(app => (
                        <div key={app.id} className="application-card" style={{marginBottom: 16}}>
                            <h3>Заявка от {app.date} • {app.time} <p>№ {app.orderNumber}</p></h3>
                            <div className="card-hr">
                                <div>
                                    <span
                                        className={`status ${app.status.toLowerCase()}`}>{app.status === 'Approved' ? 'Согласовано' : app.status === 'Pending' ? 'На рассмотрении' : 'Не согласовано'}
                                    </span>
                                    <h3>{app.date} • {app.time}</h3>
                                </div>
                                <img src={app.imgUrl} alt="" className="user-image"/>
                            </div>
                            <p className="card-tt"><p>ФИО</p> <p>{app.user}</p></p>


                            {app.sessions.map((session, index) => (
                                <div key={index} className="session">
                                    <p className="card-tt" style={{marginBottom: 0}}>
                                        <p>{session.day} • {session.date}</p>  <p>{session.time}</p></p>
                                    {/*<p className="card-tt" style={{marginBottom:0}}><p>Предоплата картой</p>  <p>{session.prepay}</p></p>*/}
                                    <p className="card-tt" style={{marginBottom: 0}}><p>Оплата на месте</p>
                                        <p>{session.onSpot}</p></p>
                                </div>
                            ))}


                            <textarea placeholder="Комментарий" value={app.comment}></textarea>

                            {app.status === 'Pending' && (
                                <div className="actions">
                                    <button onClick={() => handleReject(app.id)}>Отклонить</button>
                                    <button onClick={() => handleAccept(app.id)}>Принять</button>
                                </div>
                            )}

                            <button>Написать</button>
                        </div>
                    ))}
                </div>
            </div>

            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="btn__admin">
                        <a href="" style={{color: "#1BA524", backgroundColor: "#D8FFD8"}}> Добавить объявление</a>
                    </div>

                    <div className="srok__admin">
                        <span>Вы можете поднять объявление в топ <strong>1 раз</strong> в течение <strong>14 дней</strong></span>
                        <span>Объявление поднимается каждые <strong>2 часа в течение 8 часов</strong> в рамках одного дня</span>
                        <span style={{marginBottom: 4}}>Место вашего объявления: <strong>33</strong> из 85</span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>

                    <h1 style={{marginTop: 4}}>Предложения</h1>

                    <div className="predloj-cards">
                        {sortedPredloj.map((predloj, index) => (
                            <div key={index} className="card-predloj">
                                <div className="ls">
                                    <span>{predloj.day} <p></p> {predloj.dataPR}</span>

                                    <h2>{predloj.time}</h2>
                                </div>

                                <div className="rs">
                                    <div onClick={openModalMenu}><p onClick={openModalMenu}></p><p
                                        onClick={openModalMenu}></p><p onClick={openModalMenu}></p></div>

                                    <h2>{predloj.price}</h2>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>

            {isModalMenuOpen && (
                <div className="modal">
                    <div className="modal-menu" style={{margin: 16}}>
                        <div className="modal-menu-header" style={{height: "100%"}}>
                            <div className="modal-stroke"
                                 style={{minHeight: 63, paddingBottom: 0, paddingTop: 0}}>Внести изменения
                            </div>
                            <hr/>
                            <div className="modal-stroke"
                                 style={{minHeight: 63, paddingBottom: 0, paddingTop: 0}}>Статус «Забронировано»
                            </div>
                            <hr/>
                            <div className="modal-stroke"
                                 style={{minHeight: 63, paddingBottom: 0, paddingTop: 0}}>Удалить
                            </div>
                        </div>

                        <button className="apply-btn" onClick={closeModalMenu}>Отмена</button>
                    </div>
                </div>
            )}
            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}
            <div className="calendar">
                <div onClick={toggleOpen2}>
                    <h1>Дата и время</h1>
                    <p></p>
                </div>

                <div className={`mxw  ${isOpen2 ? 'open' : ''}`}>
                    <div className="months" >
                        {months.map((m, index) => (
                            <button
                                key={index}
                                className={`month-button ${selectedMonth === index ? 'active' : ''}`}
                                onClick={() => selectMonth(index)}
                            >
                                {m.month}
                            </button>
                        ))}
                    </div>

                    {selectedMonth !== null && (
                        <div className={`days ${expanded ? 'days-active' : ''}`}>
                            {expanded && (
                                <div className="year-display">
                                    <h2>{months[selectedMonth].month} 2024</h2> {/* Добавляем строку с годом */}
                                </div>
                            )}

                            <div className="days-grid-cont">


                                <div className={`days-grid ${expanded ? 'grid-active' : ''}`}>
                                    {rotateWeekDays(getFirstDayOfWeek(2024, selectedMonth)).map((day, index) => (
                                        <div key={index} className="week-day">
                                            {day}
                                        </div>
                                    ))}
                                    {selectedMonth !== null && selectedMonth > 0 && months[selectedMonth] && (
                                        Array.from({length: getFirstDayOfWeek(2024, selectedMonth)}).map((_, index) => {
                                            const prevMonthIndex = selectedMonth === 0 ? 11 : selectedMonth - 1; // Декабрь для января
                                            const prevMonthDays = months[prevMonthIndex]?.days || 0; // Количество дней в предыдущем месяце
                                            const day = prevMonthDays - getFirstDayOfWeek(2024, selectedMonth) + index + 1;
                                            return (
                                                <div key={`prev-${index}`} className="day gray-out">
                                                    <div>{day}</div>
                                                    {/* Предыдущие числа серым */}
                                                </div>
                                            );
                                        })
                                    )}


                                    {selectedMonth !== null && months[selectedMonth]?.days && (
                                        Array.from({length: months[selectedMonth].days}, (_, i) => i + 1).map(day => (
                                            <div
                                                key={day}
                                                className={`day ${selectedDay === day ? 'selected-day' : ''} ${day === today.day && selectedMonth === today.month && today.year === 2024 ? '' : ''}`}
                                                onClick={() => selectDay(day)}>
                                                <div>{day}<p>, {getDayOfWeek(2024, selectedMonth, day)}</p></div>
                                                <div className="priced">{price} р.</div>

                                                {day === today.day && selectedMonth === today.month && today.year === 2024 && (
                                                    <div className="point"></div>
                                                )}
                                            </div>
                                        )).slice(0, expanded ? months[selectedMonth].days : 7)
                                    )}
                                    {!expanded && (
                                        <p className="next-month-heading">{months[selectedMonth + 1]?.month.slice(0, months[selectedMonth + 1]?.month === 'June' || months[selectedMonth + 1]?.month === 'July' ? 4 : 3)}</p>
                                    )}

                                    {selectedMonth !== null && months[selectedMonth]?.days && (
                                        Array.from({length: 42 - (getFirstDayOfWeek(2024, selectedMonth) + months[selectedMonth].days)}).map((_, index) => (
                                            <div key={`next-${index}`} className="day gray-out">
                                                <div>{index + 1}</div>
                                                {/* Следующие числа серым */}
                                            </div>
                                        )).slice(0, expanded ? undefined : 7)
                                    )}
                                </div>

                                <button className="expand-button" onClick={toggleExpand3}>
                                    {expanded ? <div className="arrow-up"></div> : <div className="arrow-down"></div>}
                                </button>
                            </div>
                        </div>
                    )}

                    {selectedDay && (
                        <div className="time-options">
                            {timeOptions[selectedDay]?.length > 0 ? (
                                timeOptions[selectedDay].map((option, index) => (
                                    <div
                                        key={index}
                                        className={`time-option ${selectedTimes.includes(option.time) ? 'selected-time' : ''}`}
                                        onClick={() => toggleTimeSelection(option.time, option.price)}
                                    >
                                        {option.time} · {option.price}р.
                                    </div>
                                ))
                            ) : (
                                <div className="iskl">Пока предложений нету</div>
                            )}
                        </div>
                    )}

                    {selectedTimes.length > 0 && (
                        <button className="book-button">
                            Забронировать · {totalPrice} р
                        </button>
                    )}
                </div>

            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4, marginTop: 20}}>Подробности</h1>

                <span>ИНН организации <p>{about}</p></span>
                <span>Дополнительный телефон <a style={{color: "#283DC1", textDecoration: "none"}}
                                                href="">{about}</a></span>
                <span>Сайт <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Размер площадки <p>{about}</p></span>
                <span>Тип поверхности <p>{about}</p></span>
                <span>Парковка <p>{about}</p></span>
                <span>Кафе / ресторан <p>{about}</p></span>
                <span>Хранение формы <p>{about}</p></span>
                <span>Химчистка <p>{about}</p></span>
                <span>Магазин хоккейной формы <p>{about}</p></span>
                <span>Заточка коньков <p>{about}</p></span>
                <span>Аренда хоккейной формы <p>{about}</p></span>
                <span>Трибуны <p>{about}</p></span>
                <span>Душевые <p>{about}</p></span>
                <span>Раздевалки <p>{about}</p></span>
                <span>Зона для ОФП <p>{about}</p></span>
                <span>Баня / сауна <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>

                <span>Режим работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>

                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <ReviewBlock reviews={reviews}/>

            <div className="avtor-block">
                <div className="ls-av">
                    <h1>Александр Тимофеевич Брынза</h1>
                    {/*<div className="rating-summary">*/}
                    {/*    <Stars rating={4.2} starLight={starLight} starDark={starDark} />*/}
                    {/*    <span className="reviews-count">(отзывов: 12)</span>*/}
                    {/*</div>*/}
                    <p>Размещено объявлений: <strong>6</strong></p>
                </div>
                <div className="rs-av"><img src="/Img.png" alt=""/></div>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                <span>Размещено: {}</span>
                <span>Просмотров: <strong>3</strong>{} (сегодня: {})</span>
                <span>Подписчиков: {} (сегодня: {})</span>
                <span>Добавленно в избранное: {} (сегодня: {})</span>
            </div>
        </div>
    );
};