import axios from "axios";

export async function registerNewUser(form) {
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_PUBLIC_API_URL}/api/Authorization/SingIn/Register`,
			form,
			{
				headers: {
					accept: "*/*",
					"Content-Type": "multipart/form-data",
				},
			}
		);
		return response;
	} catch (err) {
		console.log(err);
		throw err;
	}
}

export async function logInUser(authData) {
	try {
		const response = await axios.get(
			`${process.env.REACT_APP_PUBLIC_API_URL}/api/Authorization/LogIn/Login`,
			{
				params: {
					usernameOrEmail: authData.logEmail.toString(),
					password: authData.logPassword.toString(),
					rememberUser: true,
				},
				headers: {
					accept: "*/*",
				},
			}
		);
		return response;
	} catch (err) {
		console.log(err);
		throw err;
	}
}

export async function getCurrentUserId() {
	const token = getUserToken();

	if (token) {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/Authorization/LogIn/GetCurrentId`,
				{
					headers: {
						accept: "text/plain",
						Authorization: "Bearer " + token,
					},
				}
			);

			localStorage.setItem("userId", response.data);
			return response;
		} catch (err) {
			console.log(err);
			throw err;
		}
	} else {
		console.log("Can't get user ID: Unauthorized");
	}
}

export function getStoredUserId() {
	const userId = localStorage.getItem("userId");
	if (userId) {
		return userId;
	} else {
		console.log("Can't get user userId: Unauthorized");
		return;
	}
}

export function getUserToken() {
	const token = localStorage.getItem("token");
	if (token) {
		return token;
	} else {
		console.log("Can't get user token: Unauthorized");
		return;
	}
}

export function logout() {
	localStorage.removeItem("token");
	localStorage.removeItem("userId");
	window.location.href = "/";
}
