import styles from "./FunctionalButton.module.css";

export default function FunctionalButton({ children, onClick, addClass }) {
	const handleClick = (event) => {
		event.preventDefault();
		if (onClick) onClick();
	};
	return (
		<button
			className={`${styles.functionalButton} ${addClass ? addClass : ""}`}
			onClick={handleClick}>
			{children}
		</button>
	);
}
