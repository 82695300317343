import styles from "./SearchInput.module.css";

export default function SearchInput({
	placeholder,
	setExternalValue,
	value,
	name,
	id,
}) {
	return (
		<div className={styles.searchInput_wrapper}>
			<img src="/images/icons/search_icon.svg" alt="Поиск" />
			<input
				type="text"
				placeholder={placeholder && placeholder}
				value={value && value}
				name={name & name}
				id={id && id}
                onInput={setExternalValue && setExternalValue}
			/>
		</div>
	);
}
