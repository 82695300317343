import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
	RentItem,
	BloggerItem,
	TrainingItem,
	GoalKeeperItem,
	InsuranceItem,
	TournamentItem,
	EquipmentItem,
	EquipmentTailoringItem,
	RefereeItem,
	EquipmentRepairItem,
	DryCleaningItem,
    CoachItem
} from "./components/ProductPage";
import Insurance from "./components/Insurance";
import GoalKeepers from "./components/GoalKeepers";
import Referees from "./components/Referees";
import Bloggers from "./components/Bloggers";
import EquipmentRepair from "./components/EquipmentRepair";
import Training from "./components/Training";
import EquipmentTailoring from "./components/EquipmentTailoring";
import DryCleaning from "./components/DryCleaning";
import Tournament from "./components/Tournament";
import Equipment, {Appsss, PcVersion} from "./components/Equipment";
import Rent from "./components/Rent";
import Coaches from "./components/Coaches";
import MainPage from "./components/MainPage/MainPage";
import ResponsiveComponent from "./components/Equipment";
import Chat from "./components/Chat/Chat";
import { getStoredUserId, getUserToken } from "./actions/user";

function App() {
	const [currentUserToken, setCurrentUserToken] = useState(getUserToken());
	const [currentUserId, setCurrentUserId] = useState(getStoredUserId());

	return (
		<>
			<Router>
				<Routes>

                    {/* Разделы */}
					<Route path="/" element={<MainPage currentUserId={currentUserId} currentUserToken={currentUserToken} setCurrentUserId={setCurrentUserId} setCurrentUserToken={setCurrentUserToken}/> } />
                    <Route path="/equipment" element={<ResponsiveComponent />} />
					<Route path="/chat" element={<Chat currentUserId={currentUserId} currentUserToken={currentUserToken} />} />

					{/* <Route path="/tournament" element={<Tournament />} />
                    <Route path="/insurance" element={<Insurance />} />
                    <Route path="/goalkeepers" element={<GoalKeepers />} />
                    <Route path="/referees" element={<Referees />} />
                    <Route path="/training" element={<Training />} />
                    <Route path="/bloggers" element={<Bloggers />} />
                    <Route path="/equipment-repair" element={<EquipmentRepair />} />
                    <Route path="/equipment-tailoring" element={<EquipmentTailoring />} />
                    <Route path="/dry-cleaning" element={<DryCleaning />} />
                    <Route path="/rent" element={<Rent />} />
                    <Route path="/coaches" element={<Coaches />} />

                    <Route path="/equipment/:id" element={<EquipmentItem />} />
                    <Route path="/tournament/:id" element={<TournamentItem />} />
					<Route path="/insurance/:id" element={<InsuranceItem />} />
					<Route path="/training/:id" element={<TrainingItem />} />
					<Route path="/goalkeepers/:id" element={<GoalKeeperItem />} />
					<Route path="/referees/:id" element={<RefereeItem />} />
					<Route path="/bloggers/:id" element={<BloggerItem />} />
					<Route path="/equipment-repair/:id" element={<EquipmentRepairItem />} />
					<Route path="/equipment-tailoring/:id" element={<EquipmentTailoringItem />} />
					<Route path="/dry-cleaning/:id" element={<DryCleaningItem />} />
					<Route path="/rent/:id" element={<RentItem />} />
                    <Route path="/coaches/:id" element={<CoachItem />} /> */}
				</Routes>
			</Router>
		</>
	);
}

export default App;
